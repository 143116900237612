import { gql } from '@apollo/client'

export const FIND_MANY_FAQ = gql`
  query ($where: FAQQuestionWhereInput) {
    findManyFAQQuestion(where: $where) {
      id
      title
    }
  }
`

export const FIND_UNIQUE_FAQ = gql`
  query ($where: FAQQuestionWhereUniqueInput!) {
    findUniqueFAQQuestion(where: $where) {
      id
      title
      description
      titleEng
      descriptionEng
    }
  }
`
export const CREATE_ONE_FAQ = gql`
  mutation ($data: FAQQuestionCreateInput!) {
    createOneFAQQuestion(data: $data) {
      id
    }
  }
`
export const UPDATE_ONE_FAQ = gql`
  mutation ($where: FAQQuestionWhereUniqueInput!, $data: FAQQuestionUpdateInput!) {
    updateOneFAQQuestion(where: $where, data: $data) {
      id
    }
  }
`
export const DELETE_ONE_FAQ = gql`
  mutation ($where: FAQQuestionWhereUniqueInput!) {
    deleteOneFAQQuestion(where: $where) {
      id
    }
  }
`
