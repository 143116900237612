import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'
import {
  HomeOutlined,
  HistoryOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
  UserOutlined,
  FileImageFilled,
  SettingFilled,
  ReadOutlined,
} from '@ant-design/icons'
import { useUser } from '../utils/hooks'

const MenuComponent = () => {
  const { user, loading } = useUser()
  const { pathname } = window.location

  if (loading) return 'Загрузка...'
  if (!user) return 'Ошибка'
  const role = user?.role

  return (
    <Menu theme='dark' mode='inline' defaultSelectedKeys={[pathname]}>
      <Menu.Item style={{ marginTop: 7 }} key={`/`}>
        <MenuLink to={`/`}>
          <HomeOutlined style={{ marginRight: 8 }} />
          Главная
        </MenuLink>
      </Menu.Item>
      {role.canViewRoutePage && (
        <Menu.Item style={{ marginTop: 7 }} key={`/routes`}>
          <MenuLink to={`/routes`}>
            <SwapOutlined style={{ marginRight: 8 }} />
            Круизы
          </MenuLink>
        </Menu.Item>
      )}
      {role.canViewRouteHistory && (
        <Menu.Item style={{ marginTop: 7 }} key={`/history`}>
          <MenuLink to={`/history`}>
            <HistoryOutlined style={{ marginRight: 8 }} />
            История круизов
          </MenuLink>
        </Menu.Item>
      )}
      {role.canViewRole && (
        <Menu.Item style={{ marginTop: 7 }} key={`/admins`}>
          <MenuLink to={`/admins`}>
            <UserOutlined style={{ marginRight: 8 }} />
            Права
          </MenuLink>
        </Menu.Item>
      )}
      {role.canFAQ && (
        <Menu.Item style={{ marginTop: 7 }} key={`/faq`}>
          <MenuLink to={`/faq`}>
            <QuestionCircleOutlined style={{ marginRight: 8 }} />
            FAQ
          </MenuLink>
        </Menu.Item>
      )}
      {role.canFAQ && (
        <Menu.Item style={{ marginTop: 7 }} key={`/news`}>
          <MenuLink to={`/news`}>
            <ReadOutlined style={{ marginRight: 8 }} />
            Новости
          </MenuLink>
        </Menu.Item>
      )}
      {role.canViewRoutePage && (
        <Menu.Item style={{ marginTop: 7 }} key={`/gallery`}>
          <MenuLink to={`/gallery`}>
            <FileImageFilled style={{ marginRight: 8 }} />
            Галерея
          </MenuLink>
        </Menu.Item>
      )}
      {role.canEditSettings && (
        <Menu.Item style={{ marginTop: 7 }} key={`/settings`}>
          <MenuLink to={`/settings`}>
            <SettingFilled style={{ marginRight: 8 }} />
            Настройки
          </MenuLink>
        </Menu.Item>
      )}
    </Menu>
  )
}

const MenuLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: white;
  }
`

export default MenuComponent
