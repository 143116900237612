import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row } from 'antd'
import { CREATE_ONE_IMAGE, DELETE_ONE_IMAGE, FIND_MANY_IMAGES } from '../../gqls/images'
import { PlusOutlined } from '@ant-design/icons'
import { UPLOAD } from '../../gqls/upload'

const Gallery = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)

  const { data, loading, error, refetch } = useQuery(FIND_MANY_IMAGES)
  const [upload] = useMutation(UPLOAD)
  const [createPhoto] = useMutation(CREATE_ONE_IMAGE)
  const [deletePhoto] = useMutation(DELETE_ONE_IMAGE)

  const handleDelete = async (id) => {
    await deletePhoto({ variables: { where: { id } } })
    refetch()
  }

  const handleImageUnload = async (event) => {
    const {
      validity,
      files: [file],
    } = event.target
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          console.log(data.singleUpload, 'DATA')
          setUploadFile('https://admin.yaktors.ru/upload-images/' + data.singleUpload)
          // setUploadFile('https://yaktors.ru/image/'+data.singleUpload)
        },
        // (data) => setRouteImage('https://yaktors.ru/image/'+data.singleUpload),
        onError: (er) => console.log(er),
      })
    }
  }

  const handleUpload = async (values) => {
    const { title } = values
    createPhoto({
      onCompleted: refetch,
      variables: {
        data: {
          title: title ? title : undefined,
          image: uploadFile,
          gallery: {
            connect: {
              id: 'clvpg2utm003u990lqcnqne85',
            },
          },
        },
      },
    })
    setUploadFile(null)
    setModalVisible(false)
  }

  const uploadForm = (
    <Form onFinish={handleUpload}>
      <Form.Item name='file' label='Загрузить фото' rules={[{ required: true, message: 'Выберите файл' }]}>
        <Input onChange={handleImageUnload} accept='.png, .jpg, .jpeg' type='file' />
      </Form.Item>
      <Form.Item name='title' label='Название' rules={[{ required: true, message: 'Введите название' }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button disabled={uploadFile ? false : true} type='primary' htmlType='submit'>
          Загрузить
        </Button>
      </Form.Item>
    </Form>
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
    <>
      <Row gutter={16}>
        {data.findManyImages.map((photo) => (
          <Col span={8} key={photo.id}>
            <Card
              title={photo.title ? photo.title : 'Нету заголовка'}
              style={{ marginBottom: '16px' }}
              cover={
                <a href={photo?.image} target='blank'>
                  <img src={photo.image} alt={photo.title} style={{ width: '100%', height: 300, objectFit: 'cover' }} />
                </a>
              }
              actions={[
                <Popconfirm title='Удалить этот элемент?' onConfirm={() => handleDelete(photo.id)}>
                  <Button type='danger' key='delete'>
                    Удалить
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        ))}
        <Col span={8}>
          <Card
            style={{
              marginBottom: '16px',
              height: '96%',
              textAlign: 'center',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setModalVisible(true)}
          >
            <PlusOutlined style={{ fontSize: '48px' }} />
          </Card>
        </Col>
      </Row>
      <Modal title='Добавить фотографию' open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
        {uploadForm}
        {/* Форма или компонент для добавления фотографии */}
        {/* Реализуйте его согласно вашим требованиям */}
      </Modal>
    </>
  )
}

export default Gallery
