import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import { useEffect, useState } from 'react'
const { TextArea } = Input

const NewsBlockModalForm = ({ isUpdateBlock, isModalVisible, setBlocks, blocks, setIsModalVisible }) => {
  const [paragraphs, setParagraphs] = useState([''])
  const [blockForm] = Form.useForm()

  useEffect(() => {
    if (isUpdateBlock) {
      setParagraphs(isUpdateBlock.paragraphs)
      blockForm.setFieldsValue({ title: isUpdateBlock.title })
    } else {
      setParagraphs([''])
      blockForm.resetFields()
    }
  }, [isUpdateBlock, blockForm])

  const defaultClose = () => {
    setIsModalVisible(false)
    setParagraphs([''])
    blockForm.resetFields()
  }
  const handleAdd = (v) => {
    const obj = { ...v, paragraphs }
    setBlocks([...blocks, obj])
    defaultClose()
  }
  const UpdateBlock = (v) => {
    const obj = { ...v, paragraphs }
    setBlocks(blocks.map((el, index) => (index === isUpdateBlock.index ? obj : el)))
    defaultClose()
  }

  const deleteBlock = (block) => {
    setBlocks(blocks.filter((el, index) => index !== block.index))
    defaultClose()
  }
  return (
    <Modal
      title={isUpdateBlock ? 'Обновить блок ' + (isUpdateBlock.index + 1) : 'Добавить блок ' + (blocks.length + 1)}
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        isUpdateBlock && (
          <Button key='delete' type='ghost' danger onClick={() => deleteBlock(isUpdateBlock)}>
            Удалить блок
          </Button>
        ),
        <Button key='go' type='primary' onClick={() => blockForm.submit()}>
          {isUpdateBlock ? 'Обновить' : 'Добавить'} блок
        </Button>,
      ]}
    >
      <Form form={blockForm} onFinish={isUpdateBlock ? UpdateBlock : handleAdd}>
        <Form.Item name='title' rules={[{ required: true }]} label='Заголовок'>
          <Input />
        </Form.Item>
        {paragraphs.map((paragraph, index) => (
          <Form.Item key={index} required label={'Параграф ' + (index + 1)}>
            <div className='flex' style={{ alignItems: 'center' }}>
              <TextArea
                rows={4}
                value={paragraph}
                onChange={(e) => setParagraphs([...paragraphs.slice(0, index), e.target.value, ...paragraphs.slice(index + 1)])}
              />
              {paragraphs.length > 1 ? (
                <MinusCircleOutlined
                  style={{ fontSize: 24, marginLeft: '8px', color: '#999' }}
                  onClick={() => setParagraphs([...paragraphs.slice(0, index), ...paragraphs.slice(index + 1)])}
                />
              ) : null}
            </div>
          </Form.Item>
        ))}
        <Button onClick={() => setParagraphs([...paragraphs, ''])}>Добавить параграф</Button>
      </Form>
    </Modal>
  )
}

export default NewsBlockModalForm
