import TodayDate from './TodayDate'
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer'

const StatementDoc = ({ data, guys }) => {
  let adult = 0,
    kids = 0
  for (let guy of guys) {
    if (new Date(Date.now() - new Date(guy.dateOfBirth)).getUTCFullYear() >= 12) {
      adult++
    } else kids++
  }
  const arrivedCount = guys.filter((item) => item.arrived).length
  const totals = [
    {
      title: 'Детский',
      count: kids,
    },
    {
      title: 'Пассажирский',
      count: adult,
    },
    {
      title: 'Итого пассажиров:',
      count: kids + adult,
    },
    {
      title: 'Пассажиров прибыло:',
      count: arrivedCount,
    },
    {
      title: 'Пассажиров не прибыло:',
      count: guys.length - arrivedCount,
    },
  ]
  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
  })
  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: 'Roboto',
    },
    general: {
      marginBottom: 20,
    },
    bold: {
      fontWeight: 'bold',
      fontSize: 12,
      marginLeft: 20,
    },
    text: {
      marginVertical: 5,
      fontSize: 12,
      textAlign: 'justify',
    },
    h1: {
      marginVertical: 5,
      marginBottom: 15,
      fontSize: 16,
      textAlign: 'justify',
    },
    flex: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    row: {
      flexDirection: 'row',
    },
    lil: {
      fontSize: 8,
      paddingHorizontal: 5,
      borderWidth: 1,
      width: '100%',
      borderColor: 'gray',
    },
    checkbox: {
      width: 20,
      height: 20,
      borderWidth: 1,
      borderColor: 'gray',
      marginHorizontal: 5,
    },
    podpis: {
      position: 'relative',
      top: 20,
      width: 100,
      borderTopWidth: 1,
      borderColor: '#000',
      textAlign: 'center',
      fontSize: 10,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  })

  return (
    <Document>
      <Page style={styles.body}>
        <View>
          <Text style={{ fontSize: 8 }}>{TodayDate(new Date())}</Text>
          <Text style={styles.h1}>Посадочная ведомость</Text>
          <View style={{ ...styles.general, ...styles.flex }}>
            <View>
              <View style={styles.flex}>
                <Text style={styles.text}>Отправление:</Text>
                <Text style={styles.bold}>{TodayDate(new Date(data.date))}</Text>
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Маршрут:</Text>
                <Text style={styles.bold}>{data.route.join(' - ')}</Text>
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Перевозчик:</Text>
                <Text style={styles.bold}>ООО «Ленатурфлот»</Text>
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Капитан:</Text>
                <Text style={styles.bold}></Text>
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Теплоход:</Text>
                <Text style={styles.bold}>{data.ship === 1 ? 'МИХАИЛ СВЕТЛОВ' : 'ДЕМЬЯН БЕДНЫЙ'}</Text>
              </View>
            </View>
            <View style={styles.status}>
              <View style={styles.flex}>
                <Text style={styles.text}>Отправление:</Text>
                <View style={styles.checkbox} />
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Прибыл:</Text>
                <View style={styles.checkbox} />
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Путевой лист:</Text>
                <View style={styles.checkbox} />
              </View>
              <View style={styles.flex}>
                <Text style={styles.text}>Платформа:</Text>
                <View style={styles.checkbox} />
              </View>
            </View>
          </View>
          <Text style={styles.h1}>Пассажиры</Text>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={{ ...styles.lil, width: '24%' }}>№</Text>
              <Text style={styles.lil}>ФИО</Text>
              <Text style={{ ...styles.lil, width: '39%' }}>№ каюты</Text>
              <Text style={styles.lil}>Удостоверение</Text>
              <Text style={{ ...styles.lil, width: '75%' }}>Питание</Text>
              <Text style={{ ...styles.lil, width: '55%' }}>Цена путевки</Text>
              <Text style={{ ...styles.lil, width: '40%' }}>Прибыл</Text>
            </View>
            {guys
              .sort((a, b) => a.room - b.room)
              .map((item, index) => (
                <View key={`${index}`} style={styles.row}>
                  <Text style={{ ...styles.lil, width: '24%' }}>{index + 1}</Text>
                  <Text style={styles.lil}>{item.surname + ' ' + item.name + ' ' + item.patronymic}</Text>
                  <Text style={{ ...styles.lil, width: '39%' }}>{item.room}</Text>
                  <Text style={styles.lil}>{item.documentType + ': ' + item.documentNumber}</Text>
                  <Text style={{ ...styles.lil, width: '75%' }}>{item.food}</Text>
                  <Text style={{ ...styles.lil, width: '55%' }}>{item?.bookedId ? item.amount : item.amount/100}</Text>
                  <Text style={{ ...styles.lil, width: '40%' }}>{item.arrived ? 'Да' : 'Нет'}</Text>
                </View>
              ))}
          </View>
          <View style={{ marginTop: 10 }} />
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.lil}>Итого по ведомости</Text>
              <Text style={styles.lil}>Кол-во</Text>
            </View>
            {totals.map((item, index) => (
              <View key={`${index}`} style={styles.row}>
                <Text style={styles.lil}>{item.title}</Text>
                <Text style={styles.lil}>{item.count}</Text>
              </View>
            ))}
          </View>

          <View style={{ ...styles.flex, width: '80%', marginBottom: 30, marginTop: 40 }}>
            <Text>Диспетчер:</Text>
            <Text style={styles.podpis}>(подпись)</Text>
            <Text style={{ ...styles.podpis, width: 150 }}>(ФИО)</Text>
          </View>
          <View style={styles.control}>
            <View style={{ ...styles.flex, marginBottom: 20 }}>
              <Text style={styles.text}>Посторонних предметов в салоне нет.</Text>
              <View style={styles.flex}>
                <Text style={{ ...styles.text, marginHorizontal: 20 }}>Контролер:</Text>
                <Text style={styles.podpis}>(подпись)</Text>
              </View>
            </View>
            <View style={styles.flex}>
              <Text style={styles.text}>Напечатано:</Text>
              <View style={styles.flex}>
                <Text style={{ ...styles.text, marginHorizontal: 20 }}>Капитан:</Text>
                <Text style={styles.podpis}>(подпись)</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      </Page>
    </Document>
  )
}

export default StatementDoc
