import { useQuery } from '@apollo/client'
import StatementDoc from '../../components/StatementDoc'
import Top from '../../components/Top'
import { FIND_MANY_BOOKED_ROOM, FIND_ONE_LIL_ROUTE } from '../../gqls/tours'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import './docs.css'
import { useUser } from '../../utils/hooks'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { DatePicker, Button } from 'antd'
import { FIND_MANY_TICKETS } from '../../gqls/tickets'
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker
const Start = '2024-05-01T04:07:23.708Z'
const Today = new Date()
const Todaystart = new Date()
Todaystart.setUTCHours(0, 0, 0, 0)

const Statement = ({ match, history }) => {
  const { id } = match.params
  const [dates, setDates] = useState([Start, Today])
  const { user, loading: loadingMe } = useUser()

  useEffect(() => {
    if (!user?.role?.canViewReport) history.goBack()
  })

  const { data: dataRoute, loading: loadingRoute } = useQuery(FIND_ONE_LIL_ROUTE, {
    fetchPolicy: 'network-only',
    variables: { where: { id } },
  })

  const { data: dataBooked, loading: loadingBooked } = useQuery(FIND_MANY_BOOKED_ROOM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        route: { id: { equals: id } },
        createdAt: dates[0] && { gte: new Date(dates[0]).toISOString(), lte: new Date(dates[1]).toISOString() },
        isReturned: { equals: false },
      },
    },
  })

  const { data, loading } = useQuery(FIND_MANY_TICKETS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        route: { id: { equals: id } },
        isReturned: { equals: false },
        createdAt: dates[0] && { gte: new Date(dates[0]).toISOString(), lte: new Date(dates[1]).toISOString() },
      },
    },
  })

  console.log(dataRoute)
  const isDisabled = dates[0] === Start && dates[1] === Today
  const isDisabled2 = dates[0] === Todaystart && dates[1] === Today

  const getAllPassengers = () => {
    let ticketsPassengers = []
    let bookedRoomsPassengers = []
    for (let item of data?.findManyTicket) {
      for (let passenger of item.passengers) {
        passenger.amount = item.amount
        passenger.room = item.room
      }
      ticketsPassengers = ticketsPassengers.concat(item.passengers)
    }
    for (let item of dataBooked?.findManyBookedRoom) {
      for (let passenger of item.passengers) {
        passenger.amount = item.amount
        passenger.room = item.room
      }
      bookedRoomsPassengers = bookedRoomsPassengers.concat(item.passengers)
    }
    console.log(ticketsPassengers, bookedRoomsPassengers)
    return [...ticketsPassengers, ...bookedRoomsPassengers]
  }
  return (
    <>
      <Top
        title='Ведомость'
        action={
          !(loading || loadingMe || loadingBooked || loadingRoute) && (
            <PDFDownloadLink document={<StatementDoc data={dataRoute?.findUniqueRoute} guys={getAllPassengers()} />} filename='lol'>
              {({ loading }) => (loading ? <div className='btn'>Загрузка...</div> : <div className='btn'>Скачать PDF</div>)}
            </PDFDownloadLink>
          )
        }
      />
      <div className='flex' style={{ gap: 20, marginBottom: 20 }}>
        <RangePicker allowClear={false} value={[dayjs(dates[0]), dayjs(dates[1])]} onChange={(dates, dateStrings) => setDates(dateStrings)} />
        <Button onClick={() => setDates([Start, Today])} disabled={isDisabled} type='primary' ghost>
          За все время
        </Button>
        <Button onClick={() => setDates([Todaystart, Today])} disabled={isDisabled2} type='primary' ghost>
          За сегодня
        </Button>
      </div>
      {!(loading || loadingMe || loadingBooked || loadingRoute) && (
        <PDFViewer className='doc'>
          <StatementDoc data={dataRoute?.findUniqueRoute} guys={getAllPassengers()} />
        </PDFViewer>
      )}
    </>
  )
}
export default Statement
