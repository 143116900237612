import { Button, Form, message, Input, Spin, Checkbox } from 'antd'
import Top from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useUser } from '../../utils/hooks'
import { CREATE_ONE_NEWS } from '../../gqls/news'
import { UPLOAD } from '../../gqls/upload'
import NewsBlockModalForm from '../../components/NewsBlockModalForm'
const { TextArea } = Input

const CreateOneNews = ({ history }) => {
  const { user, loading: loadingMe } = useUser()
  const [uploadFile, setUploadFile] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUpdateBlock, setIsUpdateBlock] = useState(false)
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    if (!user?.role?.canFAQ) history.goBack()
  })

  const [upload] = useMutation(UPLOAD)

  const [createOneNews, { loading }] = useMutation(CREATE_ONE_NEWS, {
    onCompleted: () => history.push('/news'),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleCreate = (v) => {
    createOneNews({ variables: { data: { ...v, images: [uploadFile], blocks } } })
  }

  const handleImageUnload = async (event) => {
    const {
      validity,
      files: [file],
    } = event.target
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          console.log(data.singleUpload, 'DATA')
          setUploadFile('https://admin.yaktors.ru/upload-images/' + data.singleUpload)
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const openBlockModal = (block, index) => {
    setIsUpdateBlock({ ...block, index })
    setIsModalVisible(true)
  }

  const openNewBlockModal = () => {
    setIsUpdateBlock(false)
    setIsModalVisible(true)
  }

  if (loadingMe) return <Spin />
  if (!user?.role?.canFAQ) return 'Ошибка'
  return (
    <>
      <Top title='Добавить новость' />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Заголовок' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='mTitle' label='Малый заголовок' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' rules={[{ required: true }]} label='Основной текст'>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item name='mDescription' label='Малый текст' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <h3>Блоки</h3>
          <div className='flex' style={{ gap: 10, marginBottom: 24 }}>
            {blocks.map((block, index) => (
              <Button key={index} onClick={() => openBlockModal(block, index)}>
                Блок {index + 1}
              </Button>
            ))}
            <Button onClick={openNewBlockModal}>Добавить</Button>
          </div>
          <Form.Item name='visible' initialValue={true} valuePropName='checked'>
            <Checkbox>Видимость</Checkbox>
          </Form.Item>
          <Form.Item name='blocked' valuePropName='checked'>
            <Checkbox>Блокировка</Checkbox>
          </Form.Item>
          <Form.Item label='Загрузить фото' rules={[{ required: true, message: 'Выберите файл' }]}>
            <Input onChange={handleImageUnload} accept='.png, .jpg, .jpeg' type='file' />
          </Form.Item>
          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
        <NewsBlockModalForm
          blocks={blocks}
          setBlocks={setBlocks}
          isUpdateBlock={isUpdateBlock}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
    </>
  )
}

export default CreateOneNews
