import { gql } from '@apollo/client'

export const FIND_ONE_LIL_ROUTE = gql`
  query ($where: RouteWhereUniqueInput!) {
    findUniqueRoute(where: $where) {
      id
      food
      foodKids
      Pricing
      date
      route
      ship
    }
  }
`

export const FIND_MANY_ROUTE = gql`
  query ($where: RouteWhereInput) {
    findManyRoute(where: $where) {
      id
      visible
      image
      food
      foodKids
      foodRequired
      Pricing
      duration
      date
      createdAt
      Desc
      DescEng
      route
      routeEng
      ship
      #
      title
      mTitle
      subtitle
      mDescription
      mDescriptionEng
      programl
      daysInterval
      programms
      programmDesc
      advantages
      routeImage
      roomImages
      inRoomStuff
      excludedDates
      images
      #
      ticket {
        id
        isReturned
        createdAt
        room
        purchasedIn
        amount
        orderId
        passengers {
          id
          name
          surname
          patronymic
          phone
          dateOfBirth
          documentType
          documentNumber
          food
          arrived
          ticketId
        }
      }
      bookedRoom {
        id
        createdAt
        isReturned
        room
        amount
        comment
        admin {
          name
          surname
          email
          role {
            title
            isLTF
          }
        }
        route {
          id
          visible
          image
          food
          foodKids
          foodRequired
          Pricing
          duration
          date
          createdAt
          Desc
          DescEng
          route
          routeEng
          ship
        }
        passengers {
          id
          name
          surname
          patronymic
          phone
          dateOfBirth
          documentType
          documentNumber
          food
          arrived
          bookedId
        }
      }
    }
  }
`

export const CREATE_ONE_ROUTE = gql`
  mutation ($data: RouteCreateInput!) {
    createOneRoute(data: $data) {
      id
    }
  }
`
export const UPDATE_ONE_ROUTE = gql`
  mutation ($data: RouteUpdateInput!, $where: RouteWhereUniqueInput!) {
    updateOneRoute(data: $data, where: $where) {
      id
    }
  }
`
export const DELETE_ONE_ROUTE = gql`
  mutation ($where: RouteWhereUniqueInput!) {
    deleteOneRoute(where: $where) {
      id
    }
  }
`
export const UPDATE_ONE_PASSENGERS = gql`
  mutation ($data: PassengersUpdateInput!, $where: PassengersWhereUniqueInput!) {
    updateOnePassengers(data: $data, where: $where) {
      id
    }
  }
`
export const FIND_MANY_BOOKED_ROOM = gql`
  query ($where: BookedRoomWhereInput) {
    findManyBookedRoom(where: $where) {
      id
      createdAt
      isReturned
      room
      foodAmount
      route {
        ship
        route
        date
        duration
        food
        foodKids
      }
      amount
      passengers {
        id
        createdAt
        name
        surname
        patronymic
        phone
        dateOfBirth
        documentType
        documentNumber
        food
        arrived
        bookedId
      }
      admin {
        name
        surname
        email
        role {
          title
          isLTF
        }
      }
    }
  }
`
