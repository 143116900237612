import styles from './pdf.module.scss'
import currency from 'currency.js'
import { ROOMS_COUNT } from './rooms'
import { rubles } from 'rubles'
import { mergeAndSumDuplicateObjects } from '../utils/hooks'

const SHIPS = ['', 'Михаил Светлов', 'Демьян Бедный']

function shortenUUID(uuid) {
  const strippedUUID = uuid.replace(/[^0-9]/g, '')

  const numberValue = parseInt(strippedUUID) % 1000000

  const paddedValue = numberValue.toString().padStart(6, '0')

  return paddedValue
}

function foodPriceIndex(food) {
  switch (food) {
    case 'Шведский стол':
      return 0
    case 'Завтрак':
      return 1
    case 'Обед':
      return 2
    case 'Ужин':
      return 3
    case 'all':
      return 0
    case 'breakfast':
      return 1
    case 'lunch':
      return 2
    case 'dinner':
      return 3
    case 'Не выбрано':
      return 4
    case 'no':
      return 4
    default:
      return null
  }
}

function is18YearsOld(birthDate) {
  const currentDate = new Date()
  const birthDateObj = new Date(birthDate)
  const timeDiff = currentDate - birthDateObj
  const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25))
  return age >= 18
}

function getFoodsPrice(ticketData) {
  let counter = 0
  ticketData.passengers.map((item, index) => {
    if (is18YearsOld(item.dateOfBirth)) {
      if (item?.food && item?.food !== 'no' && item?.food !== 'Не выбрано') {
        console.log(item?.food)
        counter = counter + ticketData.route.food[foodPriceIndex(item?.food)]
      }
    } else {
      if (item?.food && item?.food !== 'no' && item?.food !== 'Не выбрано') {
        counter = counter + ticketData.route.foodKids[foodPriceIndex(item?.food)]
      }
    }
  })
  return counter
}

const foodItems = [
  {
    label: 'Шведский стол',
    value: 'all',
  },
  {
    label: 'Завтрак',
    value: 'breakfast',
  },
  {
    label: 'Обед',
    value: 'lunch',
  },
  {
    label: 'Ужин',
    value: 'dinner',
  },
  {
    label: 'Не выбрано',
    value: 'no',
  },
]

const PDF = ({ ticketData, ids, persentCommissionFee }) => {
  console.log(persentCommissionFee, ticketData, ids, 'FEES')
  const date = new Date(ticketData.route.date).toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })

  const number = shortenUUID(ticketData?.id)

  const foodsPrice = getFoodsPrice(ticketData)

  // const feeValue = ticketData.__typename=='BookedRoom' ? 0 : ((ticketData.amount-foodsPrice) / (persentCommissionFee*100)) * 10

  const amountWithoutFee = currency(((ticketData.amount - foodsPrice) / (100 + persentCommissionFee * 100)) * 100).value
  // const amountWithoutFee = currency((ticketData.amount/((1+persentCommissionFee)*100)) * 100 ).divide(100).value
  console.log(amountWithoutFee, foodsPrice, 'amountWithoutFee')
  return (
    <div style={{ display: 'none' }}>
      <div id={ids[0]} className={styles.wrapper}>
        <p className={styles.f14}>Туроператор/Турагент</p>
        <p>Общество с ограниченной ответственностью "Ленатурфлот"</p>
        {/* <p>"Ленатурфлот"</p> */}
        <p className={`${styles.small} ${styles.shift1}`}>(Полное и сокращенное наименование, адрес (место нахождения))</p>
        <p>ООО "Ленатурфлот"</p>
        <p className={`${styles.small} ${styles.shift2}`}>(Почтовый адрес, телефон, ИНН, код по ОКПО, реестровый номер - для туроператора)</p>
        <p>Адрес (место нахождения, почтовый адрес): 677000, г</p>
        <p>Якутск, ул. Дзержинского 2</p>
        <p>Тел./факс: (4112)42-43-24,42-51-51</p>
        <p>ИНН: 1435135982, код ОКПО 14444674</p>
        <p>Реестровый номер: РТО 014274</p>
        <br />
        <br />
        <p className={styles.title}>Путевка серия</p>
        <br />
        <div className={styles.flex1}>
          <p>к договору № {number}</p>
          <p>от {date}</p>
        </div>
        <br />
        <div className={styles.wrapper1}>
          <div className={styles.flex2}>
            <p>Наименование оздоровительного учреждения: </p> <p>{SHIPS[ticketData.route.ship]}</p>
          </div>
          <p className={`${styles.small} ${styles.selfEnd}`}>(Пансионат на базе теплохода)</p>
          <div className={styles.flex}>
            <p>Вид услуги:</p>
            <p className={styles.shift3}>Круиз {ticketData.route.route.join(' - ')}</p>
          </div>
          <p className={`${styles.small} ${styles.center}`}>(Оздоровительные круиз по маршруту)</p>
          <div className={styles.flex}>
            <p>Отправление-прибытие:</p>
            <p className={styles.shift3}>
              {new Date(ticketData.route.date).toLocaleTimeString('ru-RU', {
                day: '2-digit',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit',
              })}{' '}
              -{' '}
              {new Date(new Date(ticketData.route.date).getTime() + ticketData.route.duration * 3600 * 1000).toLocaleTimeString('ru-RU', {
                day: '2-digit',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </p>
          </div>
          <div className={styles.flex}>
            <p>Ф. И. О.:</p>
            <p className={styles.shift3}>
              {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}
            </p>
          </div>
          <div className={styles.flex}>
            <p>Документ удостоверяющий личность:</p>
            <p className={styles.shift3}>
              {ticketData.passengers[0].documentType} {ticketData.passengers[0].documentNumber}
            </p>
          </div>
          <div className={styles.flex}>
            <p>Каюта:</p>
            <p className={styles.shift3}>{ticketData.room}</p>
          </div>
          <div className={styles.flex}>
            <p>Пансион:</p>
            <p className={styles.shift3}>{foodsPrice} руб</p>
          </div>
          <div className={styles.flex}>
            <p>Стоимость путевки: </p>
            <p className={styles.shift3}>{amountWithoutFee} руб.</p>
          </div>
          <p>( {rubles(amountWithoutFee)} )</p>
          <p className={`${styles.small} ${styles.shift4}`}>(сумма прописью)</p>
          <p>Посещение НПП "Ленские Столбы" : 500 руб</p>
          {/* <p className={styles.gap1}>Ответственное лицо:</p> */}
          <div className={`${styles.flex3}`}>
            <div style={{ position: 'relative' }}>
              {/* <p style={{ position: 'relative', zIndex: 10 }}>Лимарева Е. О.</p> */}
              {/* <p className={styles.small}>(Ф. И. О. должность)</p> */}
              <img src='/images/stamp.png' className={styles.stamp} />
            </div>
            <div>
              {/* <p>&nbsp;</p>
              <p className={styles.small}>(подпись) </p> */}
            </div>
            <div>
              <p>{date}</p>
              <p className={styles.small}>(Дата, м. п. штамп)</p>
            </div>
          </div>
          <div className={styles.flex4}>
            <div>
              <p className={styles.small}>Текст Договора заказчиком получен.</p>
              <p className={styles.small}>Условия договора заказчиком прочитаны,</p>
              <p className={styles.small}>с изложенными в настоящем договоре</p>
              <p className={styles.small}>пунктами (включая приложения 1, 2, 3, 4)</p>
              <p className={styles.small}>согласен. С условиями тарифного плана</p>
              <p className={styles.small}>ознакомлен. Заказчик обязуется ознакомить</p>
              <p className={styles.small}>туристов с условиями договора включая пр. 1, 2, 3, 4</p>
            </div>
            <div className={styles.flex} style={{ gap: '20px' }}>
              <div>
                <p>Заказчик:</p>
                <div className={styles.border}>
                  <img src='/images/sign.png' className={styles.sign} />
                </div>
              </div>
              <div>
                <p>Туроператор:</p>
                <div className={styles.border}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id={ids[1]} className={styles.tableWrapper}>
        <p className={styles.app}>Приложение №1</p>
        <p>
          Подписание настоящего Приложения ИСПОЛНИТЕЛЕМ и ЗАКАЗЧИКОМ подтверждает факт заключения Сторонами Договора о реализации турпродукта № от г..
        </p>
        <p>С порядком возврата денежных средств при отказе от круиза пассажир ознакомлен</p>
        <br />
        <p>Сведения о туре:</p>
        <table className={styles.blueTable}>
          <tbody>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Заказчик</td>
              <td className={`${styles.cell} ${styles.w50}`}>
                {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}
              </td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Контактные данные заказчика</td>
              <td className={`${styles.cell} ${styles.w50}`}>{/* {ticketData.user.email} {ticketData.user.tel} */}</td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Теплоход</td>
              <td className={`${styles.cell} ${styles.w50}`}>{SHIPS[ticketData.route.ship]}</td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Маршрут</td>
              <td className={`${styles.cell} ${styles.w50}`}>{ticketData.route.route.join('-')}</td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Дата и время отправления</td>
              <td className={`${styles.cell} ${styles.w50}`}>
                {new Date(ticketData.route.date).toLocaleTimeString('ru-RU', {
                  day: '2-digit',
                  month: 'long',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Дата и время прибытия</td>
              <td className={`${styles.cell} ${styles.w50}`}>
                {new Date(new Date(ticketData.route.date).getTime() + ticketData.route.duration * 3600 * 1000).toLocaleTimeString('ru-RU', {
                  day: '2-digit',
                  month: 'long',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w50}`}>Продолжительность тура</td>
              <td className={`${styles.cell} ${styles.w50}`}>{ticketData.route.duration}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p className={`${styles.center} ${styles.bold}`}>Расчет стоимости договора</p>
        <table className={styles.blueTable}>
          <tbody>
            <tr>
              <td className={`${styles.cell} ${styles.w15}`}>№</td>
              <td className={`${styles.cell} ${styles.w15}`}>Наименование товара</td>
              <td className={`${styles.cell} ${styles.w15}`}>Единица измерения</td>
              <td className={`${styles.cell} ${styles.w15}`}>Количество</td>
              <td className={`${styles.cell} ${styles.w15}`}>Цена</td>
              <td className={`${styles.cell} ${styles.w15}`}>Сумма</td>
            </tr>
            <tr>
              <td className={`${styles.cell} ${styles.w15}`}>1</td>
              <td className={`${styles.cell} ${styles.w15}`}>Путевка</td>
              <td className={`${styles.cell} ${styles.w15}`}>шт.</td>
              <td className={`${styles.cell} ${styles.w15}`}>1</td>
              <td className={`${styles.cell} ${styles.w15}`}>{amountWithoutFee.toFixed(2)}</td>
              <td className={`${styles.cell} ${styles.w15}`}>{amountWithoutFee.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15} ${styles.textEnd}`}>Итого:&nbsp;</td>
              <td className={`${styles.w15} ${styles.cell}`} style={{ textAlign: 'center' }}>
                {amountWithoutFee}
              </td>
            </tr>
            <tr>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15} ${styles.textEnd}`}>НДС в том числе:&nbsp;</td>
              <td className={`${styles.w15} ${styles.cell}`} style={{ textAlign: 'center' }}>
                -----
              </td>
            </tr>
            <tr>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15}`}></td>
              <td className={`${styles.w15} ${styles.textEnd}`}>Всего к оплате:&nbsp;</td>
              <td className={`${styles.w15} ${styles.cell}`} style={{ textAlign: 'center' }}>
                {amountWithoutFee}
              </td>
            </tr>
          </tbody>
        </table>
        <p>Порядок возврата денежных средств при отказе Заказчика от круиза</p>
        <p>
          В соответствии со ст. 32 Закона РФ "О защите прав потребителя" потребитель вправе отказаться от исполнения договора о выполнении работ
          (оказании услуг) в любое время при условии оплаты исполнителю фактически понесенных им расходов, связанных с исполнением обязательств по
          данному договору.
        </p>
        <p>В случае отказа Заказчика от круиза Исполнитель производит возврат денежных средств в следующем порядке.</p>
        <p>- При отказе от круиза по инициативе заказчика за 15 дней до даты оправления в размере 80 % уплаченной стоимости.</p>
        <p>- При отказе от круиза за 10 дней до даты оправления в размере 40 % уплаченной стоимости.</p>
        <p>- При отказе от круиза за 5 дней до даты оправления в размере 20 % уплаченной стоимости.</p>
        <p>- При отказе от круиза менее чем за 5 дней денежные средства, уплаченные Заказчиком, возврату не подлежат</p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
          }}
        >
          <div style={{ width: '48%' }}>
            <p style={{ textAlign: 'center' }} className={styles.bold}>
              ИСПОЛНИТЕЛЬ
            </p>
            <br />
            <p className={styles.small}>ООО "Ленатурфлот"</p>
            <p className={styles.small}>677000, г. Якутск, ул. Дзержинского 2</p>
            <p className={styles.small}>тел.: (4112)42-43-24,42-51-51</p>
            <p className={styles.small}>ИНН/КПП 1435135982/143501001</p>
            <p className={styles.small}>р/с 40702810476000005227</p>
            <p className={styles.small}>Якутское отделение №8603 ПАО "Сбербанк"</p>
            <p className={styles.small}>к/с 30101810400000000609</p>
            <p className={styles.small}>БИК 049805609</p>
            <br />
            <br />
            <p className={styles.small}>Генеральный директор</p>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid black',
                position: 'relative',
              }}
            >
              <img src='/images/sign.png' className={styles.sign2} />
              <img src='/images/stamp.png' className={styles.stamp2} />
              <img />
              <p className={styles.small} style={{ textAlign: 'right' }}>
                / Егоров Б.Т.
              </p>
            </div>
          </div>
          <div style={{ width: '48%' }}>
            <p style={{ textAlign: 'center' }} className={styles.bold}>
              ЗАКАЗЧИК
            </p>
            <br />
            <p className={styles.small}>
              {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}
            </p>
            <p className={styles.small}>{ticketData.passengers[0].documentType}</p>
            <p className={styles.small}>{ticketData.passengers[0].documentNumber}</p>
            <p className={styles.small}>&nbsp;</p>
            <p className={styles.small}>&nbsp;</p>
            <p className={styles.small}>&nbsp;</p>
            <p className={styles.small}>&nbsp;</p>
            <p className={styles.small}>&nbsp;</p>
            <br />
            <br />
            <p className={styles.small}>&nbsp;</p>
            <div style={{ width: '100%', borderBottom: '1px solid black' }}>
              <p className={styles.small} style={{ textAlign: 'right' }}>
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id={ids[2]} className={styles.wrapper}>
        <p className={styles.f14}>Туроператор/Турагент</p>
        <p>Общество с ограниченной ответственностью "Ленатурфлот"</p>
        <p className={`${styles.small} ${styles.shift1}`}>(Полное и сокращенное наименование, адрес (место нахождения))</p>
        <p>ООО "Ленатурфлот"</p>
        <p className={`${styles.small} ${styles.shift2}`}>(Почтовый адрес, телефон, ИНН, код по ОКПО, реестровый номер - для туроператора)</p>
        <p>Адрес (место нахождения, почтовый адрес): 677000, г</p>
        <p>Якутск, ул. Дзержинского 2</p>
        <p>Тел./факс: (4112)42-43-24,42-51-51</p>
        <p>ИНН: 1435135982, код ОКПО 14444674</p>
        <br />
        <p className={styles.title} style={{ fontWeight: 700 }}>
          Посадочный талон
        </p>
        <div className={styles.flex1}>
          <p>к договору № {number}</p>
          <p>от {date}</p>
        </div>
        <div className={styles.flex}>
          <p>Заказчик туристского продукта:</p>
          <p className={styles.shift3}>
            {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}{' '}
            {ticketData.passengers[0].documentType} {ticketData.passengers[0].documentNumber}
          </p>
        </div>
        <p className={styles.small} style={{ marginLeft: '20px', textAlign: 'center' }}>
          (ФИО, данные паспорта, а при его отсутствии - иного документа, удостоверяющего личность в соответствии с законодательством Россиской
          Федерации, место жительства - для физ. лица)
        </p>
        <div className={styles.flex}>
          <p>Количество туристов:</p>
          <p className={styles.shift3}>{ticketData.passengers?.length}</p>
        </div>
        <div>
          <p>Туристы:</p>
          <div>
            <table style={{ width: '100%', marginTop: 8, border: '1px solid black', borderCollapse: 'collapse', textAlign: 'center' }}>
              <tr>
                <th className={styles.cell}>№</th>
                <th className={styles.cell}>ФИО</th>
                <th className={styles.cell}>Документ</th>
                <th className={styles.cell}>Номер документа</th>
                <th className={styles.cell}>Питание</th>
                <th className={styles.cell}>Цена питания, ₽</th>
              </tr>
              {ticketData.passengers.map((el, index) => (
                <tr key={el.id}>
                  <td className={styles.cell}>{index + 1}</td>
                  <td className={styles.cell}>
                    {el.surname} {el.name} {el.patronymic}
                  </td>
                  <td className={styles.cell}>{el.documentType}</td>
                  <td className={styles.cell}>{el.documentNumber}</td>
                  <td className={styles.cell}>{foodItems[foodPriceIndex(el.food)].label}</td>
                  <td className={styles.cell}>
                    {foodPriceIndex(el.food) === 4
                      ? 0
                      : ticketData.route[is18YearsOld(el?.dateOfBirth) ? 'food' : 'foodKids'][foodPriceIndex(el.food)]}
                  </td>
                </tr>
              ))}
              <tr>
                <th className={styles.cell} colSpan={5}>
                  Итого
                </th>
                <th className={styles.cell}>{foodsPrice}</th>
              </tr>
            </table>
          </div>
        </div>
        <p className={styles.small} style={{ marginLeft: '20px', textAlign: 'center' }}>
          (ФИО, данные паспорта, а при его отсутствии - иного документа, удостоверяющего личность в соответствии с законодательством Россиской
          Федерации, место жительства - для физ. лица)
        </p>
        <p className={styles.small} style={{ textAlign: 'center' }}>
          (Вид услуги по ОКУН)
        </p>
        <div>
          Круиз на т/х "{ticketData.route.ship == 1 ? 'Михаил Светлов' : 'Демьян Бедный'}" по маршруту:{' '}
          {ticketData.route.route.map((item) => {
            return item + ' -'
          })}
        </div>
        <div className={styles.flex}>
          <p>Продолжительность:</p>
          <p className={styles.shift3}>{ticketData.route.duration}</p>
        </div>
        {/* <p className={styles.small} style={{ textAlign: 'center' }}>
          (Количество дней на одного человека)
        </p> */}
        <div className={styles.flex}>
          <p>Номер и категория каюты:</p>
          <p className={styles.shift3}>
            {ticketData.room} {Object.entries(ROOMS_COUNT).find((e) => e[1].includes(ticketData.room))?.[0]}
          </p>
        </div>
        <div className={styles.flex}>
          <p>Отправление-прибытие:</p>
          <p className={styles.shift3}>
            {new Date(ticketData.route.date).toLocaleTimeString('ru-RU', {
              day: '2-digit',
              month: 'long',
              hour: '2-digit',
              minute: '2-digit',
            })}
            -{' '}
            {new Date(new Date(ticketData.route.date).getTime() + ticketData.route.duration * 3600 * 1000).toLocaleTimeString('ru-RU', {
              day: '2-digit',
              month: 'long',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        </div>
        <div className={styles.flex}>
          <p>Общая цена туристического продукта:</p>
          <p className={styles.shift3}>{amountWithoutFee} руб.</p>
        </div>
        <p>( {rubles(amountWithoutFee)} )</p>
        <p className={styles.small} style={{ textAlign: 'center' }}>
          (сумма прописью)
        </p>
        <br />
        <p style={{ textAlign: 'center' }}>Полученного лицом, ответственным за совершение операции и правильность ее оформления:</p>
        <br />
        <br />
        <div className={`${styles.flex3}`}>
          <div style={{ position: 'relative' }}>
            <p style={{ position: 'relative', zIndex: 10 }}></p>
            <p className={styles.small}>(Ф. И. О. должность)</p>
            <img src='/images/stamp.png' className={styles.stamp3} />
          </div>
          <div>
            <p>&nbsp;</p>
            <p className={styles.small}>(подпись) </p>
          </div>
          <div>
            <p>{date}</p>
            <p className={styles.small}>(Дата, м. п. штамп)</p>
          </div>
        </div>
        <div className={styles.flex4}>
          <div>
            <p className={styles.small}>Текст Договора заказчиком получен.</p>
            <p className={styles.small}>Условия договора заказчиком прочитаны,</p>
            <p className={styles.small}>с изложенными в настоящем договоре</p>
            <p className={styles.small}>пунктами (включая приложения 1, 2, 3, 4)</p>
            <p className={styles.small}>согласен. С условиями тарифного плана</p>
            <p className={styles.small}>ознакомлен. Заказчик обязуется ознакомить</p>
            <p className={styles.small}>туристов с условиями договора включая пр. 1, 2, 3, 4</p>
          </div>
          <div className={styles.flex} style={{ gap: '20px' }}>
            <div>
              <p>Заказчик:</p>
              <div className={styles.border}>
                <img src='/images/sign.png' className={styles.sign} />
              </div>
            </div>
            <div>
              <p>Туроператор:</p>
              <div className={styles.border}></div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div id={ids[3]} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 32, width: 720 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <img style={{ width: 320, height: 160 }} src='/itcLogo.png' />
          <div style={{ textAlign: 'end' }}>
            <div>
              <strong>
                ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ <br></br> «Информационные Технологии Саха»
              </strong>
            </div>
            <div>677000 РС (Якутия), г. Якутск, проспект Ленина 1 офис 711</div>
            <div>
              <strong>E-mail:</strong> yaktors@gmail.com ИНН/КПП 1435336463/143501001
            </div>
            <div>Филиал "Хабаровский " АО "Альфа-Банк», БИК:040813770</div>
            <div>Кор. Счёт: 30101810800000000770 Номер счета: 40702810520080000557</div>
          </div>
        </div>
        <div style={{ height: 2.5, width: '100%', marginBottom: 16, backgroundColor: '#000' }} />
        <div style={{ width: '100%', textAlign: 'end', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
            <tr>Утверждена</tr>
          </div>
          <div>
            <tr>Письмом Министерства финансов</tr>
          </div>
          <div>
            <tr>Российской Федерации</tr>
          </div>
          <div>
            <tr>от 16.06.1994 г. N 16-30-64</tr>
          </div>
        </div>

        <div style={{ marginTop: 16 }}>Электронная Квитанция разных сборов.</div>
        <div style={{ marginTop: 32 }}>Серия ____________ №_____________ </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 40 }}>
          <div>
            <tr>
              ФИО: {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}
            </tr>
          </div>
          <div>
            <tr>Плательщик: физическое лицо</tr>
          </div>
          <div>
            <tr>Дата составления: {date}</tr>
          </div>
          <div>
            <tr>Дата расчёта:{date}</tr>
          </div>
        </div>

        <table style={{ width: '100%', marginTop: 16, border: '1px solid black', borderCollapse: 'collapse' }}>
          <tr>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>№</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Вид услуги</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Количество</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Сумма (руб.)</th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>1</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Сбор за оформление тура</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>1</th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{amountWithoutFee * persentCommissionFee}₽</th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={3}>
              Итого
            </th>
            <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{(amountWithoutFee * persentCommissionFee).toFixed(2)}₽</th>
          </tr>
        </table>

        <div style={{ display: 'flex', width: '100%', marginTop: 48 }}>
          <div>
            <tr>Всего услуг 1 на {currency(amountWithoutFee * persentCommissionFee).value} рублей.</tr>
            {/* <tr>( {rubles(currency(amountWithoutFee * persentCommissionFee).divide(100).value)} ).</tr> */}
            <tr>Агент по продаже Зверева К.С</tr>
          </div>
          <img style={{ width: 160, height: 120 }} src='./itcKerryPrint.png' />
          <img style={{ width: 196, height: 180 }} src='./itcPrint.png' />
        </div>
      </div>
      {/*  */}
    </div>
  )
}

export default PDF
