import html2pdf from 'html2pdf.js'

export const generatePdf = (ids) => {
  const elem1 = document.getElementById(ids[0])
  const elem2 = document.getElementById(ids[1])
  const elem3 = document.getElementById(ids[2])
  const elem4 = document.getElementById(ids[3])

  const opts = {
    filename: 'adminBookedTickets.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 3 },
    // jsPDF:{ unit: 'in', format: 'a4', orientation: 'p' }
  }

  html2pdf().set(opts).from(elem1).save()
  html2pdf().set(opts).from(elem2).save()
  html2pdf().set(opts).from(elem3).save()
  html2pdf().from(elem4).set(opts).save()
}

export const genPdfTravelPack = (ids) => {
  const elem1 = document.getElementById(ids[0])

  const opts = {
    filename: 'travelPackage.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 5 },
    jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' },
  }

  html2pdf().set(opts).from(elem1).save()
}

export const genStrictReportingPdf = (ids) => {
  const elem1 = document.getElementById(ids[0])
  const elem2 = document.getElementById(ids[1])
  const elem3 = document.getElementById(ids[2])
  const elem4 = document.getElementById(ids[3])
  // const elem1 = document.getElementById(ids[0]);

  const opts = {
    filename: 'StrictReporting.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 5 },
    jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' },
  }

  html2pdf().set(opts).from(elem1).save()
  html2pdf().set(opts).from(elem2).save()
  html2pdf().set(opts).from(elem3).save()
  html2pdf().set(opts).from(elem4).save()
}
