import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import Top from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_NEWS } from '../../gqls/news'
import TodayDate from '../../components/TodayDate'
const limit = 50

const News = ({ history }) => {
  const { user } = useUser()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canFAQ) history.goBack()
  })

  const { data, loading } = useQuery(FIND_MANY_NEWS, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyNews : []
  const total = data ? data?.findManyNews.length : 0
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => TodayDate(new Date(createdAt)),
    },
  ]

  if (user?.role?.canFAQ) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/news/update/${record.id}`}>Изменить</Link>,
    })
  }
  return (
    <>
      <Top title='Новости' action={user?.role?.canFAQ && <Link to={`/news/create`}>Добавить</Link>} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default News
