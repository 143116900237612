import { useQuery } from '@apollo/client'
import Top from '../../components/Top'
import { DatePicker, Col, Row, Statistic, Button } from 'antd'
import { useEffect, useState } from 'react'
import { FIND_MANY_TICKETS } from '../../gqls/tickets'
import { FIND_MANY_BOOKED_ROOM, FIND_MANY_ROUTE } from '../../gqls/tours'
import TodayDate from '../../components/TodayDate'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUser } from '../../utils/hooks'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { genStrictReportingPdf } from '../../utils/pdfgen'
import PDFStrictReport from '../../components/pdfStrictReport'
import { FIND_MANY_SETTINGS } from '../../gqls/settings'
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker
const Today = new Date()
const Start = '2024-05-01T04:07:23.708Z'
const Todaystart = new Date()
Todaystart.setUTCHours(0, 0, 0, 0)

const Charts = ({ match, history }) => {
  const { user } = useUser()
  useEffect(() => {
    if (!user?.role?.canViewReport) history.goBack()
  })
  const { id } = match.params
  const [dates, setDates] = useState([Start, Today])
  const [ticketData, setTicketData] = useState([])
  const [total, setTotal] = useState(0)

  const { data: settings, loading: loadingSettings } = useQuery(FIND_MANY_SETTINGS)

  const { data: dataRoute, loading: loadingRoute } = useQuery(FIND_MANY_ROUTE, {
    fetchPolicy: 'network-only',
    variables: { where: { id: { equals: id } } },
  })

  const { data: dataBooked, loading: loadingBooked } = useQuery(FIND_MANY_BOOKED_ROOM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        routeId: { equals: id },
        createdAt: dates[0] && {
          gte: new Date(dates[0]).toISOString(),
          lte: new Date(dates[1]).toISOString(),
        },
        isReturned: { equals: false },
      },
    },
  })

  const { data, loading } = useQuery(FIND_MANY_TICKETS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        postId: { equals: id },
        createdAt: dates[0] && {
          gte: new Date(dates[0]).toISOString(),
          lte: new Date(dates[1]).toISOString(),
        },
        isReturned: { equals: false },
      },
    },
    onCompleted: (data) => {
      let total = 0
      let appCount = 0
      let webserviceCount = 0
      data?.findManyTicket.forEach((element) => {
        total += element.amount
        if (element.purchasedIn === 'APP') appCount += 1
        if (element.purchasedIn === 'WEBSERVICE') webserviceCount += 1
      })
      const length = data?.findManyTicket.length ? data?.findManyTicket.length : 1
      setTotal({
        total: total / 100,
        appCount,
        webserviceCount,
        appCountP: (appCount / length) * 100,
        webserviceCountP: (webserviceCount / length) * 100,
      })
    },
  })

  const Legend = () => {
    const routeTur = dataRoute?.findManyRoute[0]
    return (
      <div style={{ marginBottom: 20 }}>
        <div>
          Маршрут: <b>{routeTur.route.join(' → ')}</b>
        </div>
        <div>
          Дата отплытия: <b>{TodayDate(new Date(routeTur.date))}</b>
        </div>
        <div>
          Длительность: <b>{routeTur.duration + 'ч.'}</b>
        </div>
        <div>
          Судно: <b>{routeTur.ship === 1 ? 'МИХАИЛ СВЕТЛОВ' : 'ДЕМЬЯН БЕДНЫЙ'}</b>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const ticketData = []
    if (!loading && !loadingBooked) {
      for (const obj of data?.findManyTicket) {
        const { id, route, amount, room, passengers } = obj
        const newAmount = amount / 100
        if (passengers[0]) {
          ticketData?.push({ id, route, amount: newAmount, room, passengers })
        }
      }
      for (const obj of dataBooked?.findManyBookedRoom) {
        const { id, route, amount, room, passengers } = obj
        if (passengers[0]) {
          ticketData?.push({ id, route, amount, room, passengers })
        }
      }

      // const arrSize = Math.ceil(ticketData.length / 4)
      // const pdfPart1 = ticketData.slice(0, arrSize)
      // const pdfPart2 = ticketData.slice(arrSize, arrSize * 2)
      // const pdfPart3 = ticketData.slice(arrSize * 2, arrSize * 3)
      // const pdfPart4 = ticketData.slice(arrSize * 3)
      setTicketData([ticketData])
      // setTicketData(ticketData)
    }
  }, [data, dataBooked])

  // const newArray = ticketDatas?.slice(0, ticketDatas?.length);

  const Buttons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <NavButton>
        <Link to={`/routes/charts/sales/${id}`}>Продажи</Link>
      </NavButton>
      <NavButton>
        <Link to={`/routes/charts/booked/sales/${id}`}>Продажи (бронь)</Link>
      </NavButton>
      <NavButton>
        <Link to={`/routes/charts/statement/${id}`}>Ведомость</Link>
      </NavButton>
      <Link to={`/routes/charts/ltf/${id}`}>Ведомость ЛТФ</Link>
      {ticketData[0] ? (
        <>
          <Button onClick={() => genStrictReportingPdf([id + '1'])} type='primary' style={{ marginLeft: 16 }}>
            Скачать строгую отчетность
          </Button>
          <PDFStrictReport
            ticketDatas={ticketData[0]}
            ids={id + '1'}
            persentCommissionFee={settings?.findManySettings[0]?.persentCommissionFee}
          ></PDFStrictReport>
          {/* <PDFStrictReport
            ticketDatas={ticketData[1]}
            ids={id + '2'}
            persentCommissionFee={settings?.findManySettings[0]?.persentCommissionFee}
          ></PDFStrictReport>
          <PDFStrictReport
            ticketDatas={ticketData[2]}
            ids={id + '3'}
            persentCommissionFee={settings?.findManySettings[0]?.persentCommissionFee}
          ></PDFStrictReport>
          <PDFStrictReport
            ticketDatas={ticketData[3]}
            ids={id + '4'}
            persentCommissionFee={settings?.findManySettings[0]?.persentCommissionFee}
          ></PDFStrictReport> */}
        </>
      ) : null}
    </div>
  )
  console.log(ticketData)
  const isDisabled = dates[0] === Start && dates[1] === Today
  const isDisabled2 = dates[0] === Todaystart && dates[1] === Today
  return (
    <>
      <Top title='Отчет' action={<Buttons />} />
      {!loadingRoute && <Legend />}
      <div className='flex' style={{ gap: 20 }}>
        <RangePicker allowClear={false} value={[dayjs(dates[0]), dayjs(dates[1])]} onChange={(dates, dateStrings) => setDates(dateStrings)} />
        <Button onClick={() => setDates([Start, Today])} disabled={isDisabled} type='primary' ghost>
          За все время
        </Button>
        <Button onClick={() => setDates([Todaystart, Today])} disabled={isDisabled2} type='primary' ghost>
          За сегодня
        </Button>
      </div>
      <div style={{ marginTop: 20 }}>
        {loading || loadingRoute || loadingBooked || loadingSettings ? (
          'Загрузка...'
        ) : (
          <div>
            <Row>
              <Col span={12}>
                <Statistic title='Выручка' value={total.total} suffix=' ₽' precision={2} />
              </Col>
              <Col span={12}>
                <Statistic title='Путёвок продано' value={data?.findManyTicket.length} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <Statistic title='Куплено в приложении' value={total.appCount} suffix={`/ ${Math.round(total.appCountP)}%`} />
              </Col>
              <Col span={12}>
                <Statistic title='Куплено на сайте' value={total.webserviceCount} suffix={`/ ${Math.round(total.webserviceCountP)}%`} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <Statistic title='Забронированно админом' value={dataBooked?.findManyBookedRoom?.length} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

const NavButton = styled.div`
  padding: 5px 10px 5px 0;
  margin-right: 10px;
  border-right: 1px solid #d9d9d9;
`
export default Charts
