import { useMutation } from '@apollo/client'
import { Button, DatePicker, Form, Input, message, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'
import { UPDATE_ONE_PASSENGERS } from '../gqls/tours'
import moment from 'moment'
const { Option } = Select
const FormUpdateModal = ({ visible, foodRequired, setModVis, initial, refetch, modalToggle }) => {
  const [docType, setDocType] = useState('Паспорт')
  const [form] = Form.useForm()
  const visToggle = () => {
    initial.setInitial(null)
    setModVis(!visible)
  }
  useEffect(() => {
    if (initial.initial) {
      initial.initial.date = moment(initial.initial.dateOfBirth)
      form.setFieldsValue(initial.initial)
    } else {
      form.resetFields()
    }
  }, [initial.initial, form])

  const [updateOnepassenger, { loading }] = useMutation(UPDATE_ONE_PASSENGERS, {
    onCompleted: () => {
      refetch()
      form.resetFields()
      visToggle()
      modalToggle()
    },
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleAdd = (v) => {
    const obj = { ...v }
    for (let key of Object.keys(v)) {
      obj[key] = { set: v[key] }
    }
    delete obj.date
    obj.dateOfBirth = { set: v.date.toISOString() }
    updateOnepassenger({ variables: { where: { id: initial.initial.id }, data: obj } })
  }

  const onDocTypeChange = (e) => {
    form.setFieldsValue({ documentNumber: '' })
    setDocType(e)
  }

  const getLength = () => {
    if (docType === 'Паспорт') return 10
    if (docType === 'Свидетельство о рождении') return 9
    return null
  }
  return (
    <Modal title='Обновление данных пассажира' open={visible} footer={null} onCancel={visToggle}>
      <Form form={form} onFinish={handleAdd} labelCol={{ span: 8 }}>
        <Form.Item name='surname' rules={[{ required: true }]} label='Фамилия'>
          <Input />
        </Form.Item>
        <Form.Item name='name' rules={[{ required: true }]} label='Имя'>
          <Input />
        </Form.Item>
        <Form.Item name='patronymic' rules={[{ required: true }]} label='Отчество'>
          <Input />
        </Form.Item>
        <Form.Item name='phone' rules={[{ required: true }]} label='Телефон'>
          <Input />
        </Form.Item>
        <Form.Item name='date' rules={[{ required: true }]} label='Дата рождения'>
          <DatePicker />
        </Form.Item>
        <Form.Item name='documentType' rules={[{ required: true }]} label='Документ'>
          <Select onChange={(e) => onDocTypeChange(e)} placeholder='Выберите документ'>
            <Option value='Паспорт'>Паспорт</Option>
            <Option value='Свидетельство о рождении'>Свидетельство о рождении</Option>
            <Option value='Заграничный паспорт'>Заграничный паспорт</Option>
            <Option value='Иностранный документ'>Иностранный документ</Option>
          </Select>
        </Form.Item>
        <Form.Item name='documentNumber' rules={[{ required: true }]} label='Серия, номер'>
          <Input minLength={getLength()} maxLength={getLength()} />
        </Form.Item>
        <Form.Item name='food' rules={[{ required: !foodRequired }]} label='Питание'>
          <Select disabled={foodRequired} placeholder={foodRequired ? 'Входит в стоимость' : 'Выберите питание'}>
            <Option value='Не выбрано'>Не выбрано</Option>
            <Option value='Шведский стол'>Шведский стол</Option>
            <Option value='Завтрак'>Завтрак</Option>
            <Option value='Обед'>Обед</Option>
            <Option value='Ужин'>Ужин</Option>
          </Select>
        </Form.Item>
        <div className='flex' style={{ justifyContent: 'flex-end' }}>
          <Button htmlType='submit' loading={loading} type='primary'>
            Изменить
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
export default FormUpdateModal
