import { Button, Form, message, Input, Spin, Checkbox } from 'antd'
import Top from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useUser } from '../../utils/hooks'
import { DELETE_ONE_NEWS, FIND_UNIQUE_NEWS, UPDATE_ONE_NEWS } from '../../gqls/news'
import { UPLOAD } from '../../gqls/upload'
import NewsBlockModalForm from '../../components/NewsBlockModalForm'
const { TextArea } = Input

const UpdateNews = ({ match, history }) => {
  const { id } = match.params
  const { user, loading: loadingMe } = useUser()
  const [uploadFile, setUploadFile] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUpdateBlock, setIsUpdateBlock] = useState(false)
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    if (!user?.role?.canFAQ) history.goBack()
  })

  const { data, loading: loadingNews } = useQuery(FIND_UNIQUE_NEWS, {
    fetchPolicy: 'network-only',
    variables: { where: { id } },
    onCompleted: (data) => {
      setUploadFile(data?.findUniqueNews.images[0])
      setBlocks(data?.findUniqueNews.blocks)
    },
  })

  const onCompleted = () => history.goBack()
  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [upload] = useMutation(UPLOAD)
  const [updateOneNews, { loading }] = useMutation(UPDATE_ONE_NEWS, { onCompleted, onError })

  const [deleteOneNews, { loading: loadingDel }] = useMutation(DELETE_ONE_NEWS, { onCompleted, onError })

  const handleCreate = (v) =>
    updateOneNews({
      variables: {
        data: {
          title: { set: v.title },
          description: { set: v.description },
          mTitle: { set: v.mTitle },
          mDescription: { set: v.mDescription },
          visible: { set: v.visible },
          blocked: { set: v.blocked },
          images: [uploadFile],
          blocks,
        },
        where: { id },
      },
    })

  const deleteNews = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneNews({ variables: { where: { id } } })
  }

  const handleImageUnload = async (event) => {
    const {
      validity,
      files: [file],
    } = event.target
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          console.log(data.singleUpload, 'DATA')
          setUploadFile('https://admin.yaktors.ru/upload-images/' + data.singleUpload)
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const openBlockModal = (block, index) => {
    setIsUpdateBlock({ ...block, index })
    setIsModalVisible(true)
  }

  const openNewBlockModal = () => {
    setIsUpdateBlock(false)
    setIsModalVisible(true)
  }

  if (loadingNews || loadingMe) return <Spin />
  const news = data?.findUniqueNews
  if (!news) return 'Ошибка'
  return (
    <>
      <Top title='Изменить новость' />
      <div style={{ maxWidth: 500 }}>
        <Form initialValues={news} onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Заголовок' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='mTitle' label='Малый заголовок' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' rules={[{ required: true }]} label='Основной текст'>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item name='mDescription' label='Малый текст' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <h3>Блоки</h3>
          <div className='flex' style={{ gap: 10, marginBottom: 24 }}>
            {blocks.map((block, index) => (
              <Button key={index} onClick={() => openBlockModal(block, index)}>
                Блок {index + 1}
              </Button>
            ))}
            <Button onClick={openNewBlockModal}>Добавить</Button>
          </div>
          <Form.Item name='visible' valuePropName='checked'>
            <Checkbox>Видимость</Checkbox>
          </Form.Item>
          <Form.Item name='blocked' valuePropName='checked'>
            <Checkbox>Блокировка</Checkbox>
          </Form.Item>
          <Form.Item label='Загрузить фото' rules={[{ required: true, message: 'Выберите файл' }]}>
            <Input onChange={handleImageUnload} accept='.png, .jpg, .jpeg' type='file' />
          </Form.Item>
          {news.images[0] && (
            <div style={{ marginBottom: 20 }}>
              <img src={news.images[0]} style={{ width: '100%' }} alt='Изображение' />
              <div>
                <i>Текущее изображение</i>
              </div>
            </div>
          )}
          <Button loading={loading || loadingDel} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteNews} type='primary'>
            Удалить
          </Button>
        </div>
        <NewsBlockModalForm
          blocks={blocks}
          setBlocks={setBlocks}
          isUpdateBlock={isUpdateBlock}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
    </>
  )
}

export default UpdateNews
