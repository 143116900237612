import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { notification, Button, Input } from 'antd'
import { FIND_MANY_SETTINGS, UPDATE_ONE_SETTING } from '../../gqls/settings'
import Top from '../../components/Top'

const Settings = () => {
  const [persentCommissionFee, setPersentCommissionFee] = useState()
  const [api, contextHolder] = notification.useNotification()

  const { data, loading } = useQuery(FIND_MANY_SETTINGS)

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Успешно сохранено',
      description: 'Ваше изменение процента комисионного сбора прошла успешно',
    })
  }

  const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING, {
    onCompleted: () => {
      // Alert('Успешно сохранено')
      openNotificationWithIcon('success')
    },
  })

  const handleClick = () => {
    updateOneSetting({
      variables: {
        where: {
          id: data?.findManySettings[0]?.id,
        },
        data: {
          persentCommissionFee: { set: persentCommissionFee / 100 },
        },
      },
    })
  }

  if (loading) return null
  return (
    <>
      {contextHolder}
      <Top title='Настройки' />
      <div>Процент комисионного сбора в процентах</div>
      <Input
        type='number'
        defaultValue={data?.findManySettings[0]?.persentCommissionFee * 100}
        onChange={(e) => setPersentCommissionFee(e.target.value)}
        placeholder='Процент комисионного сбора'
      />
      <div style={{ height: 16 }}></div>
      <Button onClick={() => handleClick()}>Сохранить</Button>
    </>
  )
}

export default Settings
