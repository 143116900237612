import styles from './pdf.module.scss'
import currency from 'currency.js'
import { ROOMS_COUNT } from './rooms'
import { rubles } from 'rubles'

const SHIPS = ['', 'Михаил Светлов', 'Демьян Бедный']

function shortenUUID(uuid) {
  const strippedUUID = uuid?.replace(/[^0-9]/g, '')

  const numberValue = parseInt(strippedUUID) % 1000000

  const paddedValue = numberValue.toString().padStart(6, '0')

  return paddedValue
}

const PDFStrictReport = ({ ticketDatas, ids, persentCommissionFee }) => {
  if (!ticketDatas) return null
  return (
    <div style={{ display:"none" }}>
      <div style={{ width: 1600, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} id={ids}>
        {ticketDatas?.map((ticketData, index) => {
          return (
            <div key={index} style={{ padding: 0, width: 800, height: 1100, transform: `scale(0.7)` }}>
              <p className={styles.f14}>Туроператор/Турагент</p>
              <p>Общество с ограниченной ответственностью</p>
              <p>"Ленатурфлот"</p>
              <p className={`${styles.small} ${styles.shift1}`}>(Полное и сокращенное наименование, адрес (место нахождения))</p>
              <p>ООО "Ленатурфлот"</p>
              <p className={`${styles.small} ${styles.shift2}`}>(Почтовый адрес, телефон, ИНН, код по ОКПО, реестровый номер - для туроператора)</p>
              <p>Адрес (место нахождения, почтовый адрес): 677000, г</p>
              <p>Якутск, ул. Дзержинского 2</p>
              <p>Тел./факс: (4112)42-43-24,42-51-51</p>
              <p>ИНН: 1435135982, код ОКПО 14444674</p>
              <p>Реестровый номер: РТО 014274</p>
              <br />
              <br />
              <br />
              {/* <p className={styles.title}>Путевка серия</p> */}
              {/* <p className={styles.title}>Путевка серия БС № {number}</p> */}
              <p className={styles.title}>Путевка серия БС №</p>
              <br />
              <div className={styles.flex1}>
                <p>к договору № {shortenUUID(ticketData?.id)}</p>
                <p>
                  от{' '}
                  {new Date(ticketData?.route?.date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })}
                </p>
              </div>
              <br />
              <div className={styles.wrapper1}>
                <div className={styles.flex2}>
                  <p>Наименование оздоровительного учреждения: </p> <p>{SHIPS[ticketData.route.ship]}</p>
                </div>
                <p className={`${styles.small} ${styles.selfEnd}`}>(Пансионат на базе теплохода)</p>
                <div className={styles.flex}>
                  <p>Вид услуги:</p>
                  <p className={styles.shift3}>
                    {ticketData.route.route[0]} {ticketData.route.duration}
                  </p>
                </div>
                <p className={`${styles.small} ${styles.center}`}>(Оздоровительные круиз по маршруту)</p>
                <div className={styles.flex}>
                  <p>Отправление-прибытие:</p>
                  <p className={styles.shift3}>
                    {new Date(ticketData.route.date).toLocaleTimeString('ru-RU', {
                      day: '2-digit',
                      month: 'long',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                    -{' '}
                    {new Date(new Date(ticketData.route.date).getTime() + ticketData.route.duration * 3600 * 1000).toLocaleTimeString('ru-RU', {
                      day: '2-digit',
                      month: 'long',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                </div>
                <div className={styles.flex}>
                  <p>Ф. И. О.:</p>
                  <p className={styles.shift3}>
                    {ticketData.passengers[0].surname} {ticketData.passengers[0].name} {ticketData.passengers[0].patronymic}
                  </p>
                </div>
                <div className={styles.flex}>
                  <p>Документ удостоверяющий личность:</p>
                  <p className={styles.shift3}>
                    {ticketData.passengers[0].documentType} {ticketData.passengers[0].documentNumber}
                  </p>
                </div>
                <div className={styles.flex}>
                  <p>Каюта:</p>
                  <p className={styles.shift3}>{ticketData?.room}</p>
                </div>
                {/* <div className={styles.flex}>
                  <p>Пансион:</p>
                  <p className={styles.shift3}>{ticketData?.food} руб</p>
                </div> */}
                <div className={styles.flex}>
                  <p>Стоимость путевки: </p>
                  <p className={styles.shift3}>
                    {currency(ticketData?.amount).value} 
                    руб.
                  </p>
                </div>
                <p>( {rubles(currency(ticketData?.amount).value)} )</p>
                <p className={`${styles.small} ${styles.shift4}`}>(сумма прописью)</p>
                <p>Посещение НПП "Ленские Столбы" : 500 руб</p>
                <p className={styles.gap1}>Ответственное лицо:</p>
                <div className={`${styles.flex3}`}>
                  <div style={{ position: 'relative' }}>
                    <p style={{ position: 'relative', zIndex: 10 }}>Лимарева Е. О.</p>
                    <p className={styles.small}>(Ф. И. О. должность)</p>
                    <img src='/images/stamp.png' className={styles.stamp} />
                  </div>
                  <div>
                    <p>&nbsp;</p>
                    <p className={styles.small}>(подпись) </p>
                  </div>
                  <div>
                    <p>
                      {new Date(ticketData?.route?.date).toLocaleDateString('ru-RU', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </p>
                    <p className={styles.small}>(Дата, м. п. штамп)</p>
                  </div>
                </div>
                <div className={styles.flex4}>
                  <div>
                    <p className={styles.small}>Текст Договора заказчиком получен.</p>
                    <p className={styles.small}>Условия договора заказчиком прочитаны,</p>
                    <p className={styles.small}>с изложенными в настоящем договоре</p>
                    <p className={styles.small}>пунктами (включая приложения 1, 2, 3, 4)</p>
                    <p className={styles.small}>согласен. С условиями тарифного плана</p>
                    <p className={styles.small}>ознакомлен. Заказчик обязуется ознакомить</p>
                    <p className={styles.small}>туристов с условиями договора включая пр. 1, 2, 3, 4</p>
                  </div>
                  <div className={styles.flex} style={{ gap: '20px' }}>
                    <div>
                      <p>Заказчик:</p>
                      <div className={styles.border}>
                        <img src='/images/sign.png' className={styles.sign} />
                      </div>
                    </div>
                    <div>
                      <p>Туроператор:</p>
                      <div className={styles.border}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PDFStrictReport
