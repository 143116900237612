import { useEffect, useState } from 'react'
import { Button, Form, Input, message, Select, DatePicker, InputNumber, Checkbox } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import Top from '../../components/Top'
import { DELETE_ONE_ROUTE, FIND_MANY_ROUTE, UPDATE_ONE_ROUTE } from '../../gqls/tours'
import { UPLOAD } from '../../gqls/upload'
import moment from 'moment'
import { useUser } from '../../utils/hooks'
import RoomVisibleChecbox from '../../components/RoomVisibleChecbox'
import { DeleteFilled } from '@ant-design/icons'
const { TextArea } = Input
const { Option } = Select

const UpdateRoute = ({ match, history }) => {
  const { user, loading: loadingMe } = useUser()
  useEffect(() => {
    if (!user?.role?.canUpdateRoute) history.goBack()
  })
  const { id } = match.params
  const [ship, setShip] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)
  const [foodBlocked, setFoodBlocked] = useState(false)

  const [programs, setPrograms] = useState([])

  const [advantagesItems, setAdvantagesItems] = useState([])
  const [advantagesTitle, setAdvantagesTitle] = useState('')
  const [advantagesDescription, setAdvantagesItemsDescription] = useState('')
  const [advantagesUploadFile, setAdvantagesUploadFile] = useState(null)

  const [routeImage, setRouteImage] = useState(null)
  const [images, setImages] = useState([])

  const [inputs, setInputs] = useState({
    Люкс: '',
    Полулюкс: '',
    '1-местная каюта': '',
    '2-местная каюта': '',
    '3-местная каюта': '',
    '4-местная каюта': '',
  })

  const [cabinImages, setCabinImages] = useState({
    Люкс: [],
    Полулюкс: [],
    '1-местная каюта': [],
    '2-местная каюта': [],
    '3-местная каюта': [],
    '4-местная каюта': [],
  })

  //

  // const getAdvantagesUpload = ({
  //   target: {
  //     validity,
  //     files: [file],
  //   },
  // }) => {
  //   if (validity.valid) setAdvantagesUploadFile(file)
  // }

  const handleImagesChange = (event) => {
    const {
      validity,
      files: [file],
    } = event.target
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          setImages([...images, 'https://admin.yaktors.ru/upload-images/' + data.singleUpload])
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const handleCabinImagesChange = (event) => {
    const {
      name,
      validity,
      files: [file],
    } = event.target

    let cabins = cabinImages

    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          await cabins?.[name].push('https://admin.yaktors.ru/upload-images/' + data.singleUpload)
          setCabinImages(cabins)
        },
        // (data) => setRouteImage('https://admin.yaktors.ru/upload-images/'+data.singleUpload),
        onError: (er) => console.log(er),
      })
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //

  const handleAddProgram = () => {
    const newProgram = {
      title: '',
      programDays: [],
    }

    setPrograms([...programs, newProgram])
  }

  const handleDeleteProgram = (programIndex) => {
    const updatedPrograms = [...programs]
    updatedPrograms.splice(programIndex, 1)
    setPrograms(updatedPrograms)
  }

  const handleAddProgramDay = (programIndex) => {
    const newProgramDay = {
      title: '',
      items: [],
    }

    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays.push(newProgramDay)

    setPrograms(updatedPrograms)
  }

  const handleAddItem = (programIndex, programDayIndex) => {
    const newItem = {
      time: '',
      description: '',
    }

    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays[programDayIndex].items.push(newItem)

    setPrograms(updatedPrograms)
  }

  const handleDeleteItem = (programIndex, programDayIndex, itemIndex) => {
    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays[programDayIndex].items.splice(itemIndex, 1)

    setPrograms(updatedPrograms)
  }
  //

  const handleAddAdvantagesItem = async () => {
    // 'https://admin.yaktors.ru/upload-images/' + data.singleUpload
    if (advantagesTitle && advantagesDescription && advantagesUploadFile) {
      await upload({
        variables: { upload: advantagesUploadFile },
        onCompleted: (data) => {
          const newItem = {
            title: advantagesTitle,
            description: advantagesDescription,
            image: 'https://admin.yaktors.ru/upload-images/' + data?.singleUpload,
          }
          setAdvantagesItems([...advantagesItems, newItem])
          setAdvantagesTitle('')
          setAdvantagesItemsDescription('')
          // setAdvantagesUploadFile(null)
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const handleDeleteAdvantagesItem = (index) => {
    const updatedItems = [...advantagesItems]
    updatedItems.splice(index, 1)
    setAdvantagesItems(updatedItems)
  }

  const getAdvantagesUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) setAdvantagesUploadFile(file)
  }

  const getRouteUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (data) => setRouteImage('https://admin.yaktors.ru/upload-images/' + data.singleUpload),
        onError: (er) => console.log(er),
      })
    }
    // setRouteImage(file)
  }

  //

  const { data, loading: loadingRoute } = useQuery(FIND_MANY_ROUTE, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id: { equals: id } },
    },
    onCompleted: (data) => {
      setShip(data?.findManyRoute[0]?.ship)
      data?.findManyRoute[0]?.advantages && setAdvantagesItems(data?.findManyRoute[0]?.advantages)
      data?.findManyRoute[0]?.programms && setPrograms(data?.findManyRoute[0]?.programms)
      data?.findManyRoute[0]?.routeImage && setRouteImage(data?.findManyRoute[0]?.routeImage)
      data?.findManyRoute[0]?.roomImages[0] && setCabinImages(data?.findManyRoute[0]?.roomImages[0])
      data?.findManyRoute[0]?.inRoomStuff[0] && setInputs(data?.findManyRoute[0]?.inRoomStuff[0])
      data?.findManyRoute[0]?.images && setImages(data?.findManyRoute[0]?.images)
      // inRoomStuff:inputs,
      // excludedDates:excludedDates,
      // images:images,
      if (data?.findManyRoute[0].foodRequired) setFoodBlocked(true)
    },
  })

  const goBack = () => history.goBack()
  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneRoute, { loading }] = useMutation(UPDATE_ONE_ROUTE, {
    onCompleted: goBack,
    onError,
  })

  const [deleteOneRoute, { loading: loadingDel }] = useMutation(DELETE_ONE_ROUTE, {
    onCompleted: goBack,
    onError,
  })

  const [upload, { loading: loading1 }] = useMutation(UPLOAD)
  const getUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) setUploadFile(file)
  }

  const handleUpdate = (v) => {
    const doUpdate = (upload) => {
      let routes = [v.route1 ? v.route1.trim() : r.route[0], v.route2 ? v.route2.trim() : r.route[1]]
      let routesEng = [v.route1Eng ? v.route1Eng.trim() : r.routeEng[0], v.route2Eng ? v.route2Eng.trim() : r.routeEng[1]]
      const getLastRoute = (routeTrim, myRoutes, rRoute) => {
        if (routeTrim) {
          const lastRoute = routeTrim !== '' ? routeTrim : r[rRoute][2]
          myRoutes.push(lastRoute)
        }
      }
      getLastRoute(v.route3?.trim(), routes, 'route')
      getLastRoute(v.route3Eng?.trim(), routesEng, 'routeEng')
      const getVOrR = (val) => (v[val] ? v[val] : r[val])
      const check = (bul, val) => (bul ? 1 : val)
      updateOneRoute({
        variables: {
          where: { id },
          data: {
            date: { set: getVOrR('date') },
            duration: { set: getVOrR('duration') },
            Desc: { set: getVOrR('Desc') },
            DescEng: { set: getVOrR('DescEng') },
            image: { set: upload },
            ship: { set: ship },
            //
            title: { set: getVOrR('title') },
            mTitle: { set: getVOrR('mTitle') },
            subtitle: { set: getVOrR('subtitle') },
            programl: { set: getVOrR('programl') },
            mDescription: { set: getVOrR('mDescription') },
            mDescriptionEng: { set: getVOrR('mDescriptionEng') },
            programmDesc: { set: getVOrR('programmDesc') },
            routeImage: { set: routeImage },
            //
            programms: programs,
            advantages: advantagesItems,
            roomImages: [cabinImages],
            inRoomStuff: inputs,
            images: images,
            //
            route: routes,
            routeEng: routesEng,
            food: v.foodRequired ? [1, 1, 1, 1] : [v.food1, v.food2, v.food3, v.food4],
            foodKids: v.foodRequired ? [1, 1, 1, 1] : [v.foodKids1, v.foodKids2, v.foodKids3, v.foodKids4],
            foodRequired: { set: v.foodRequired },
            Pricing: [
              check(v.cabin4_check, v.cabin4),
              check(v.cabin3_check, v.cabin3),
              check(v.cabin2_check, v.cabin2),
              check(v.cabin1_check, v.cabin1),
              check(v.halflux_check, v.halflux),
              check(v.lux_check, v.lux),
              check(v.cabin1extra_check, v.cabin1extra),
            ],
          },
        },
      })
    }
    if (uploadFile) {
      upload({
        variables: { upload: uploadFile },
        onCompleted: (data) => doUpdate('https://admin.yaktors.ru/upload-images/' + data.singleUpload),
        onError: (er) => console.log(er),
      })
    } else {
      doUpdate(r.image)
    }
  }

  const deleteRoute = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneRoute({ variables: { where: { id } } })
  }

  const deleteRouteImages = (index) => {
    let isRight = window.confirm('Вы уверены, что хотите удалить ?')
    if (isRight) {
      const items = [...images]
      items.splice(index, 1)
      setImages(items)
    }
  }

  const deleteCabinImage = (index, name) => {
    let isRight = window.confirm('Вы уверены, что хотите удалить ?')
    if (isRight) {
      console.log(cabinImages[name], 'IMAGE')
      console.log(index, 'IMAGE index')
      console.log(name, 'IMAGE index')
      const items = [...cabinImages[name]]
      const itemstwo = cabinImages
      items.splice(index, 1)

      itemstwo[name] = items

      setCabinImages(itemstwo)
      console.log(cabinImages, 'IMAGES')
    }
  }

  if (loadingRoute || loadingMe || !ship) return 'Загрузка...'
  const r = data?.findManyRoute[0]
  if (!user?.role?.canUpdateRoute) return 'Ошибка'

  return (
    <>
      <Top title='Изменить круиз' />
      <div style={{ maxWidth: 500 }}>
        <Form
          initialValues={{
            ship: ship,
            route1: r.route[0],
            route2: r.route[1],
            route3: r.route[2],
            route1Eng: r.routeEng[0],
            route2Eng: r.routeEng[1],
            route3Eng: r.routeEng[2],
            //
            title: r.title,
            mTitle: r.mTitle,
            subtitle: r.subtitle,
            programl: r.programl,
            mDescription: r.mDescription,
            mDescriptionEng: r.mDescriptionEng,
            programmDesc: r.programmDesc,
            //
            date: moment(r.date),
            duration: r.duration,
            Desc: r.Desc,
            DescEng: r.DescEng,
            food1: r.food[0],
            food2: r.food[1],
            food3: r.food[2],
            food4: r.food[3],
            foodKids1: r.foodKids[0],
            foodKids2: r.foodKids[1],
            foodKids3: r.foodKids[2],
            foodKids4: r.foodKids[3],
            cabin4: r.Pricing[0],
            cabin3: r.Pricing[1],
            cabin2: r.Pricing[2],
            cabin1: r.Pricing[3],
            halflux: r.Pricing[4],
            lux: r.Pricing[5],
            cabin1extra: r.Pricing[6],
            foodRequired: r.foodRequired,
          }}
          onFinish={handleUpdate}
          layout='horizontal'
        >
          <div className='flex'>
            <Form.Item name='route1' label='Маршрут'>
              <Input />
            </Form.Item>
            <Form.Item name='route2'>
              <Input />
            </Form.Item>
            <Form.Item name='route3'>
              <Input />
            </Form.Item>
          </div>
          <div className='flex'>
            <Form.Item name='route1Eng' label='Маршрут (Eng)'>
              <Input />
            </Form.Item>
            <Form.Item name='route2Eng'>
              <Input />
            </Form.Item>
            <Form.Item name='route3Eng'>
              <Input />
            </Form.Item>
          </div>

          {/*  */}
          <Form.Item required name='title' label='Заголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='mTitle' label='Малый заголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='subtitle' label='Подзаголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='programl' label='Заголовок программы'>
            <Input />
          </Form.Item>
          {/*  */}

          <Form.Item name='date' label='Дата отплытия'>
            <DatePicker showTime />
          </Form.Item>
          <Form.Item name='duration' label='Длительность'>
            <InputNumber min={1} max={2000} />
          </Form.Item>
          <Form.Item name='ship' label='Судно'>
            <Select onChange={(ship) => setShip(ship)} placeholder='Выберите судно'>
              <Option value={1}>МИХАИЛ СВЕТЛОВ</Option>
              <Option value={2}>ДЕМЬЯН БЕДНЫЙ</Option>
            </Select>
          </Form.Item>
          <Form.Item name='Desc' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name='DescEng' label='Описание (Eng)'>
            <TextArea rows={4} />
          </Form.Item>

          {/*  */}

          <Form.Item required name='mDescription' label='Малое описание'>
            <TextArea />
          </Form.Item>
          <Form.Item required name='mDescriptionEng' label='Малое описание (Eng)'>
            <TextArea />
          </Form.Item>

          {/*  */}
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <h3>Программа</h3>
            <button type='button' onClick={handleAddProgram}>
              Добавить программу
            </button>
            {programs.map((program, programIndex) => (
              <div key={programIndex}>
                <h2>Программа {programIndex + 1}</h2>
                <input
                  type='text'
                  placeholder='Заголовок программы'
                  value={program.title}
                  onChange={(e) => {
                    const updatedPrograms = [...programs]
                    updatedPrograms[programIndex].title = e.target.value
                    setPrograms(updatedPrograms)
                  }}
                />
                <button type='button' onClick={() => handleAddProgramDay(programIndex)}>
                  Добавить день
                </button>
                {program.programDays.map((programDay, programDayIndex) => (
                  <div key={programDayIndex}>
                    <h3>День {programDayIndex + 1}</h3>
                    <input
                      type='text'
                      placeholder='Заголовок дня'
                      value={programDay.title}
                      onChange={(e) => {
                        const updatedPrograms = [...programs]
                        updatedPrograms[programIndex].programDays[programDayIndex].title = e.target.value
                        setPrograms(updatedPrograms)
                      }}
                    />
                    <ul>
                      {programDay.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <div>
                            Время:
                            <input
                              type='text'
                              placeholder='Время'
                              value={item.time}
                              onChange={(e) => {
                                const updatedPrograms = [...programs]
                                updatedPrograms[programIndex].programDays[programDayIndex].items[itemIndex].time = e.target.value
                                setPrograms(updatedPrograms)
                              }}
                            />
                          </div>
                          <div>
                            Описание:
                            <input
                              type='text'
                              placeholder='Описание'
                              value={item.description}
                              onChange={(e) => {
                                const updatedPrograms = [...programs]
                                updatedPrograms[programIndex].programDays[programDayIndex].items[itemIndex].description = e.target.value
                                setPrograms(updatedPrograms)
                              }}
                            />
                          </div>
                          <button type='button' onClick={() => handleDeleteItem(programIndex, programDayIndex, itemIndex)}>
                            Удалить
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button type='button' onClick={() => handleAddItem(programIndex, programDayIndex)}>
                      Добавить элемент
                    </button>
                  </div>
                ))}
                <button type='button' onClick={() => handleDeleteProgram(programIndex)}>
                  Удалить программу
                </button>
              </div>
            ))}
          </div>
          {/*  */}
          <div>
            <h3>Достопримечательности</h3>
            <div>
              <input type='text' placeholder='Заголовок' value={advantagesTitle} onChange={(e) => setAdvantagesTitle(e.target.value)} />
              <input
                type='text'
                placeholder='Описание'
                value={advantagesDescription}
                onChange={(e) => setAdvantagesItemsDescription(e.target.value)}
              />
              <input onChange={getAdvantagesUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
              <button type='button' onClick={handleAddAdvantagesItem}>
                Добавить
              </button>
            </div>
            <ul>
              {advantagesItems?.map((item, index) => (
                <li key={index}>
                  <div>{item.title}</div>
                  <div>{item.description}</div>
                  <a href={item.image} target='blank'>
                    {item.image}
                  </a>
                  <button type='button' onClick={() => handleDeleteAdvantagesItem(index)}>
                    Удалить
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <Form.Item required name='programmDesc' label='Описание программы'>
            <TextArea rows={4} />
          </Form.Item>

          {/*  */}

          <h3>Цены на еду</h3>
          <Form.Item valuePropName='checked' name='foodRequired'>
            <Checkbox checked={foodBlocked} onChange={(e) => setFoodBlocked(e.target.checked)}>
              Еда включена
            </Checkbox>
          </Form.Item>
          <Form.Item name='food1' label='Шведский стол'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='food2' label='Завтрак'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='food3' label='Обед'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='food4' label='Ужин'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <h3>Цены на еду для детей с 2-11 лет</h3>
          <Form.Item name='foodKids1' label='Шведский стол'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='foodKids2' label='Завтрак'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='foodKids3' label='Обед'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item name='foodKids4' label='Ужин'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <h3>Цены на каюты</h3>
          <RoomVisibleChecbox inputName='cabin4' blocked={r.Pricing[0] === 1} label='4-местная каюта' checkboxName='cabin4_check' />
          <RoomVisibleChecbox inputName='cabin3' blocked={r.Pricing[1] === 1} label='3-местная каюта' checkboxName='cabin3_check' />
          <RoomVisibleChecbox inputName='cabin2' blocked={r.Pricing[2] === 1} label='2-местная каюта' checkboxName='cabin2_check' />
          <RoomVisibleChecbox inputName='cabin1' blocked={r.Pricing[3] === 1} label='1-местная каюта' checkboxName='cabin1_check' />
          <RoomVisibleChecbox inputName='halflux' blocked={r.Pricing[4] === 1} label='Полулюкс' checkboxName='halflux_check' />
          <RoomVisibleChecbox inputName='lux' blocked={r.Pricing[5] === 1} label='Люкс' checkboxName='lux_check' />
          <RoomVisibleChecbox
            inputName='cabin1extra'
            blocked={r.Pricing[6] === 1}
            label='1-местная каюта с доп местом'
            checkboxName='cabin1extra_check'
          />
          {/*  */}

          <h3>Что находиться в каюте ?</h3>
          <div>
            {Object.entries(inputs).map(([name, value]) => (
              <div key={name}>
                <label>{name}:</label>
                <TextArea type='text' name={name} value={value} onChange={handleInputChange} />
              </div>
            ))}
          </div>
          <h3>Изображения круиза</h3>
          <input onChange={handleImagesChange} accept='.png, .jpg, .jpeg' name='images' type='file' />
          {images && (
            <div style={{ marginBottom: 12, marginTop: 12, display: 'flex' }}>
              {images?.map((item, index) => {
                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                    <a style={{ marginLeft: 8, marginRight: 8 }} target='blank' href={item}>
                      <img alt='' style={{ height: 56, width: 56 }} src={item} />
                    </a>
                    <Button onClick={() => deleteRouteImages(index)} style={{ width: 56, fontSize: 12, marginLeft: 8, marginRight: 8 }}>
                      <DeleteFilled style={{ color: 'red' }}></DeleteFilled>
                    </Button>
                  </div>
                )
              })}
            </div>
          )}

          <h3>Изображения кают</h3>
          <div>
            {cabinImages ? (
              Object.entries(cabinImages)?.map(([name, value]) => (
                <div key={name}>
                  <label>{name}:</label>
                  <input onChange={handleCabinImagesChange} accept='.png, .jpg, .jpeg' name={name} type='file' />

                  {value[0] && (
                    <div style={{ display: 'flex' }}>
                      {value?.map((item, index) => {
                        return (
                          <div
                            style={{ marginRight: 8, marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            key={index}
                          >
                            <a target='blank' href={item} style={{ height: 50, width: 50, marginTop: 10 }}>
                              {value[0] && <img style={{ height: 50, width: 50 }} alt='' src={item} />}
                            </a>
                            <Button onClick={() => deleteCabinImage(index, name)} style={{ width: 50, fontSize: 12, marginLeft: 8, marginRight: 8 }}>
                              <DeleteFilled style={{ color: 'red' }}></DeleteFilled>
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {/* <div style={{height:50, width:50, marginTop:10, marginBottom:10}}>{value[0] && <img style={{height:50, width:50}} src={value[0]}></img>}</div> */}
                  {/* <TextArea
                type="text"
                name={name}
                value={value}
                onChange={handleInputChange}
              /> */}
                </div>
              ))
            ) : (
              <div>Не дошли</div>
            )}
          </div>

          {/*  */}
          <Form.Item label='Изображение'>
            <input onChange={getUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
          </Form.Item>
          <div style={{ marginBottom: 20 }}>
            <img src={r.image} style={{ width: '100%' }} alt='Изображение' />
            <div>
              <i>Текущее изображение</i>
            </div>
          </div>

          <Form.Item required label='Изображение тура на главной'>
            <input onChange={getRouteUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
            {routeImage ? (
              <a href={routeImage} target='blank'>
                {routeImage}
              </a>
            ) : null}
            {routeImage && (
              <div>
                <img src={routeImage} style={{ width: '100%' }} alt='Изображение' />
                <i>Текущее изображение</i>
              </div>
            )}
          </Form.Item>
          <Button htmlType='submit' loading={loading || loadingDel || loading1} type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel || loading1} onClick={deleteRoute} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateRoute
