import { Button, Form, message, Input, Spin } from 'antd'
import Top from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { DELETE_ONE_FAQ, FIND_UNIQUE_FAQ, UPDATE_ONE_FAQ } from '../../gqls/faq'
import { useEffect } from 'react'
import { useUser } from '../../utils/hooks'
const { TextArea } = Input

const UpdateFAQ = ({ match, history }) => {
  const { id } = match.params
  const { user, loading: loadingMe } = useUser()
  useEffect(() => {
    if (!user?.role?.canFAQ) history.goBack()
  })

  const { data, loading: loadingFAQ } = useQuery(FIND_UNIQUE_FAQ, {
    fetchPolicy: 'network-only',
    variables: { where: { id } },
  })

  const onCompleted = () => history.goBack()

  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneFAQ, { loading }] = useMutation(UPDATE_ONE_FAQ, { onCompleted, onError })

  const [deleteOneFAQ, { loading: loadingDel }] = useMutation(DELETE_ONE_FAQ, { onCompleted, onError })

  const handleCreate = (v) =>
    updateOneFAQ({
      variables: {
        data: {
          title: { set: v.title },
          description: { set: v.description },
          titleEng: { set: v.titleEng },
          descriptionEng: { set: v.descriptionEng },
        },
        where: { id },
      },
    })

  const deleteFAQ = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneFAQ({ variables: { where: { id } } })
  }

  if (loadingFAQ || loadingMe) return <Spin />
  const faq = data?.findUniqueFAQQuestion

  return (
    <>
      <Top title='Изменить FAQ' />
      <div style={{ maxWidth: 500 }}>
        <Form initialValues={faq} onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' rules={[{ required: true }]} label='Вопрос'>
            <Input />
          </Form.Item>
          <Form.Item name='description' rules={[{ required: true }]} label='Ответ'>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item name='titleEng' rules={[{ required: true }]} label='Вопрос на английском'>
            <Input />
          </Form.Item>
          <Form.Item name='descriptionEng' rules={[{ required: true }]} label='Ответ на английском'>
            <TextArea rows={5} />
          </Form.Item>
          <Button loading={loading || loadingDel} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteFAQ} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateFAQ
