import { Button } from 'antd'
import { generatePdf } from '../utils/pdfgen'
import PDF from './pdf'
import { useQuery } from '@apollo/client'
import { FIND_MANY_SETTINGS } from '../gqls/settings'
// import { useState } from "react"

const PdfDownloadButton = (ticketData, ids) => {
  const { data, loading } = useQuery(FIND_MANY_SETTINGS)

  return (
    <>
      {!loading && (
        <PDF ticketData={ticketData.ticketData} ids={ticketData.ids} persentCommissionFee={data?.findManySettings[0]?.persentCommissionFee} />
      )}
      <Button
        loading={loading}
        // className={cx(classes.button, classes.buttonFlex)}
        onClick={() => {
          generatePdf(ticketData.ids)
        }}
      >
        Сгенерировать и скачать документы
      </Button>
    </>
  )
}

export default PdfDownloadButton
