import { gql } from '@apollo/client'

export const FIND_MANY_SETTINGS = gql`
  query ($where: SettingsWhereInput) {
    findManySettings(where: $where) {
      id
      persentCommissionFee
    }
  }
`

export const UPDATE_ONE_SETTING = gql`
  mutation ($where: SettingsWhereUniqueInput! $data:SettingsUpdateInput!) {
    updateOneSettings(where: $where data: $data) {
      id
      persentCommissionFee
    }
  }
`