export const rooms = [
  {
    indx: 5,
    color: '#FF5757',
    h: 'Люкс',
    p: 'С дополнительной системой кондиционирования',
    max: 2,
  },
  {
    indx: 4,
    color: '#7FFF6A',
    h: 'Полулюкс',
    p: 'С дополнительной системой кондиционирования',
    max: 2,
  },
  {
    indx: 4,
    color: '#27E3C2',
    h: 'Полулюкс',
    p: 'С дополнительной системой кондиционирования',
    p1: 'С дополнительным местом',
    max: 2,
  },
  {
    indx: 3,
    color: '#EEC1EE',
    h: '1-местная каюта',
    p: 'С дополнительным местом',
    max: 2,
  },
  {
    indx: 2,
    color: '#F1C84C',
    h: '2-местная каюта',
    p: 'С дополнительной системой кондиционирования',
    max: 2,
  },
  {
    indx: 1,
    color: '#8BF8FF',
    h: '3-местная каюта',
    max: 3,
  },
  {
    indx: 0,
    color: '#FFA500',
    h: '4-местная каюта',
    max: 4,
  },
  { indx: -1, color: 'gray', h: 'Забронированно клиентом' },
  { indx: -2, color: '#000', h: 'Забронированно админом' },
  { indx: -2, color: '#020E5D', h: 'Забронированно админом без заполнения' },
]
