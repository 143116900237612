import { Button } from 'antd'

const PassengerButton = ({ onClick, name }) => {
  return (
    <Button style={{ margin: 5 }} type='primary' ghost onClick={onClick}>
      {name}
    </Button>
  )
}
export default PassengerButton
