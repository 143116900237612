import { gql } from '@apollo/client'

export const FIND_MANY_NEWS = gql`
  query ($where: NewsWhereInput) {
    findManyNews(where: $where) {
      id
      title
      createdAt
    }
  }
`

export const FIND_UNIQUE_NEWS = gql`
  query ($where: NewsWhereUniqueInput!) {
    findUniqueNews(where: $where) {
      id
      title
      mTitle
      description
      mDescription
      visible
      blocked
      images
      blocks
    }
  }
`
export const CREATE_ONE_NEWS = gql`
  mutation ($data: NewsCreateInput!) {
    createOneNews(data: $data) {
      id
    }
  }
`
export const UPDATE_ONE_NEWS = gql`
  mutation ($where: NewsWhereUniqueInput!, $data: NewsUpdateInput!) {
    updateOneNews(where: $where, data: $data) {
      id
    }
  }
`
export const DELETE_ONE_NEWS = gql`
  mutation ($where: NewsWhereUniqueInput!) {
    deleteOneNews(where: $where) {
      id
    }
  }
`
