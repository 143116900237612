import { useQuery } from '@apollo/client'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import SalesDoc from '../../components/SalesDoc'
import Top from '../../components/Top'
import { useUser } from '../../utils/hooks'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { DatePicker, Button } from 'antd'
import { FIND_MANY_BOOKED_ROOM } from '../../gqls/tours'
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker
const Start = '2024-05-01T04:07:23.708Z'
const Today = new Date()
const Todaystart = new Date()
Todaystart.setUTCHours(0, 0, 0, 0)

const BookedSales = ({ match, history }) => {
  const { id } = match.params
  const [dates, setDates] = useState([Start, Today])
  const [passengers, setPassengers] = useState([])
  const { user, loading: loadingMe } = useUser()
  useEffect(() => {
    if (!user?.role?.canViewReport) history.goBack()
  })

  const { data, loading } = useQuery(FIND_MANY_BOOKED_ROOM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        route: { id: { equals: id } },
        createdAt: dates[0] && { gte: new Date(dates[0]).toISOString(), lte: new Date(dates[1]).toISOString() },
      },
    },
    onCompleted: (data) => {
      let tours1 = []
      console.log(data, 'as')
      for (let item of data.findManyBookedRoom) {
        for (let passenger of item.passengers) {
          passenger.isReturned = item.isReturned
          passenger.route = item.route
        }
        tours1 = tours1.concat(item.passengers)
      }
      console.log('YOOOO', data, tours1)
      setPassengers(tours1)
    },
  })
  const isDisabled = dates[0] === Start && dates[1] === Today
  const isDisabled2 = dates[0] === Todaystart && dates[1] === Today
  return (
    <>
      <Top
        title='Продажи (бронь)'
        action={
          !(loading || loadingMe) && (
            <PDFDownloadLink document={<SalesDoc isBooked={true} dates={dates} data={data.findManyBookedRoom} guys={passengers} />} filename='lol'>
              {({ loading }) => (loading ? <div className='btn'>Загрузка...</div> : <div className='btn'>Скачать PDF</div>)}
            </PDFDownloadLink>
          )
        }
      />
      <div className='flex' style={{ gap: 20, marginBottom: 20 }}>
        <RangePicker allowClear={false} value={[dayjs(dates[0]), dayjs(dates[1])]} onChange={(dates, dateStrings) => setDates(dateStrings)} />
        <Button onClick={() => setDates([Start, Today])} disabled={isDisabled} type='primary' ghost>
          За все время
        </Button>
        <Button onClick={() => setDates([Todaystart, Today])} disabled={isDisabled2} type='primary' ghost>
          За сегодня
        </Button>
      </div>
      {!(loading || loadingMe) && (
        <PDFViewer className='doc'>
          <SalesDoc isBooked={true} dates={dates} data={data.findManyBookedRoom} guys={passengers} />
        </PDFViewer>
      )}
    </>
  )
}

export default BookedSales
