import { useEffect, useState } from 'react'
import { Button, Form, Input, message, DatePicker, InputNumber, Select, Checkbox } from 'antd'
import { useMutation } from '@apollo/client'
import Top from '../../components/Top'
import { CREATE_ONE_ROUTE } from '../../gqls/tours'
import { UPLOAD } from '../../gqls/upload'
import { useUser } from '../../utils/hooks'
import RoomVisibleChecbox from '../../components/RoomVisibleChecbox'
const { TextArea } = Input
const { Option } = Select
// Говнокод, костыли, молодость...
const CreateOneRoute = ({ history }) => {
  const { user, loading: loadingMe } = useUser()

  useEffect(() => {
    if (!user?.role?.canCreateRoute) history.goBack()
  })

  const [ship, setShip] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)
  const [foodBlocked, setFoodBlocked] = useState(false)

  const [programs, setPrograms] = useState([])

  const [inputs, setInputs] = useState({
    Люкс: '',
    Полулюкс: '',
    '1-местная каюта': '',
    '2-местная каюта': '',
    '3-местная каюта': '',
    '4-местная каюта': '',
  })

  const [cabinImages, setCabinImages] = useState({
    Люкс: [],
    Полулюкс: [],
    '1-местная каюта': [],
    '2-местная каюта': [],
    '3-местная каюта': [],
    '4-местная каюта': [],
  })

  //
  const [date, setDate] = useState('')

  const [excludedDates, setExcludedDates] = useState([])
  //
  const [advantagesItems, setAdvantagesItems] = useState([])
  const [advantagesTitle, setAdvantagesTitle] = useState('')
  const [advantagesDescription, setAdvantagesItemsDescription] = useState('')
  const [advantagesUploadFile, setAdvantagesUploadFile] = useState(null)

  const [routeImage, setRouteImage] = useState(null)
  const [images, setImages] = useState([])
  //
  const [upload, { loading: loading1 }] = useMutation(UPLOAD)

  //

  const handleDeleteDate = (dateToDelete) => {
    setExcludedDates((prevDates) => prevDates.filter((date) => date !== dateToDelete))
  }

  const handleDateChange = (event) => {
    setDate(event.target.value)
  }

  const handleAddDate = () => {
    if (date !== '') {
      setExcludedDates((prevDates) => [...prevDates, date])
      setDate('')
    }
  }

  // const getAdvantagesUpload = ({
  //   target: {
  //     validity,
  //     files: [file],
  //   },
  // }) => {
  //   if (validity.valid) setAdvantagesUploadFile(file)
  // }

  const handleImagesChange = (event) => {
    const {
      validity,
      files: [file],
    } = event.target
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          setImages([...images, 'https://admin.yaktors.ru/upload-images/' + data.singleUpload])
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const handleCabinImagesChange = (event) => {
    const {
      name,
      value,
      validity,
      files: [file],
    } = event.target

    let cabins = cabinImages

    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          await cabins?.[name].push('https://admin.yaktors.ru/upload-images/' + data.singleUpload)
          setCabinImages(cabins)
        },
        // (data) => setRouteImage('https://yaktors.ru/image/'+data.singleUpload),
        onError: (er) => console.log(er),
      })
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //

  const handleAddProgram = () => {
    const newProgram = {
      title: '',
      programDays: [],
    }

    setPrograms([...programs, newProgram])
  }

  const handleDeleteProgram = (programIndex) => {
    const updatedPrograms = [...programs]
    updatedPrograms.splice(programIndex, 1)
    setPrograms(updatedPrograms)
  }

  const handleAddProgramDay = (programIndex) => {
    const newProgramDay = {
      title: '',
      items: [],
    }

    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays.push(newProgramDay)

    setPrograms(updatedPrograms)
  }

  const handleAddItem = (programIndex, programDayIndex) => {
    const newItem = {
      time: '',
      description: '',
    }

    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays[programDayIndex].items.push(newItem)

    setPrograms(updatedPrograms)
  }

  const handleDeleteItem = (programIndex, programDayIndex, itemIndex) => {
    const updatedPrograms = [...programs]
    updatedPrograms[programIndex].programDays[programDayIndex].items.splice(itemIndex, 1)

    setPrograms(updatedPrograms)
  }
  //

  const handleAddAdvantagesItem = async () => {
    // 'https://admin.yaktors.ru/upload-images/' + data.singleUpload
    if (advantagesTitle && advantagesDescription && advantagesUploadFile) {
      await upload({
        variables: { upload: advantagesUploadFile },
        onCompleted: (data) => {
          const newItem = {
            title: advantagesTitle,
            description: advantagesDescription,
            image: 'https://admin.yaktors.ru/upload-images/' + data?.singleUpload,
          }
          setAdvantagesItems([...advantagesItems, newItem])
          setAdvantagesTitle('')
          setAdvantagesItemsDescription('')
          // setAdvantagesUploadFile(null)
        },
        onError: (er) => console.log(er),
      })
    }
  }

  const handleDeleteAdvantagesItem = (index) => {
    const updatedItems = [...advantagesItems]
    updatedItems.splice(index, 1)
    setAdvantagesItems(updatedItems)
  }

  const getAdvantagesUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) setAdvantagesUploadFile(file)
  }

  const getRouteUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (data) => setRouteImage('https://admin.yaktors.ru/upload-images/' + data.singleUpload),
        onError: (er) => console.log(er),
      })
    }
    // setRouteImage(file)
  }

  //

  const getUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) setUploadFile(file)
  }

  const [createOneRoute, { loading }] = useMutation(CREATE_ONE_ROUTE, {
    onCompleted: () => {
      window.location.href = `/routes`
    },
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })
  const handleCreate = (v) => {
    const doCreate = (upload) => {
      let routes = [v.route1.trim(), v.route2.trim()]
      let routesEng = [v.route1Eng.trim(), v.route2Eng.trim()]
      const getLastRoute = (lastRoute, myRoutes) => lastRoute !== '' && myRoutes.push(lastRoute)
      getLastRoute(v.route3?.trim(), routes)
      getLastRoute(v.route3Eng?.trim(), routesEng)
      const check = (bul, val) => (bul ? 1 : val)
      createOneRoute({
        variables: {
          data: {
            date: v.date,
            duration: v.duration,
            Desc: v.Desc,
            DescEng: v.DescEng,
            image: upload,
            ship: ship,
            //
            title: v.title,
            mTitle: v.mTitle,
            subtitle: v.subtitle,
            mDescription: v.mDescription,
            mDescriptionEng: v.mDescriptionEng,
            programl: v.programl,
            // daysInterval:v.daysInterval,
            programms: programs,
            programmDesc: v.programmDesc,
            advantages: advantagesItems,
            routeImage: routeImage,
            roomImages: cabinImages,
            inRoomStuff: inputs,
            // excludedDates:excludedDates,
            images: images,
            //
            route: routes,
            routeEng: routesEng,
            food: v.foodRequired ? [1, 1, 1, 1] : [v.food1, v.food2, v.food3, v.food4],
            foodKids: v.foodRequired ? [1, 1, 1, 1] : [v.foodKids1, v.foodKids2, v.foodKids3, v.foodKids4],
            foodRequired: v.foodRequired,
            Pricing: [
              check(v.cabin4_check, v.cabin4),
              check(v.cabin3_check, v.cabin3),
              check(v.cabin2_check, v.cabin2),
              check(v.cabin1_check, v.cabin1),
              check(v.halflux_check, v.halflux),
              check(v.lux_check, v.lux),
              check(v.cabin1extra_check, v.cabin1extra),
            ],
          },
        },
      })
    }
    upload({
      variables: { upload: uploadFile },
      onCompleted: (data) => doCreate('https://admin.yaktors.ru/upload-images/' + data.singleUpload),
      onError: (er) => console.log(er),
    })
  }

  if (loading || loadingMe) return null
  if (!user?.role?.canCreateRoute) return 'Ошибка'
  return (
    <>
      <Top title='Создать круиз' />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout='horizontal'>
          <div className='flex'>
            <Form.Item required name='route1' label='Маршрут'>
              <Input />
            </Form.Item>
            <Form.Item required name='route2'>
              <Input />
            </Form.Item>
            <Form.Item name='route3'>
              <Input />
            </Form.Item>
          </div>
          <div className='flex'>
            <Form.Item required name='route1Eng' label='Маршрут (Eng)'>
              <Input />
            </Form.Item>
            <Form.Item required name='route2Eng'>
              <Input />
            </Form.Item>
            <Form.Item name='route3Eng'>
              <Input />
            </Form.Item>
          </div>
          <Form.Item required name='title' label='Заголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='mTitle' label='Малый заголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='subtitle' label='Подзаголовок'>
            <Input />
          </Form.Item>
          <Form.Item required name='programl' label='Заголовок программы'>
            <Input />
          </Form.Item>
          <Form.Item required name='date' label='Дата отплытия'>
            <DatePicker showTime />
          </Form.Item>
          {/* <Form.Item required name='daysInterval' label='Интервал круизов'>
            <InputNumber min={1} max={2000} />
          </Form.Item> */}
          {/* <h3>Исключенные даты</h3>
          <div>
            <input type="date" value={date} onChange={handleDateChange} />
            <button type="button" onClick={handleAddDate}>Добавить исключение</button>
            <ul>
              {excludedDates.map((date, index) => (
                <li key={index}>
                  {date}{' '}
                  <button type="button" onClick={() => handleDeleteDate(date)}>Удалить</button>
                </li>
              ))}
            </ul>
          </div> */}
          <Form.Item required name='duration' label='Длительность'>
            <InputNumber min={1} max={2000} />
          </Form.Item>
          <Form.Item required name='ship' label='Судно'>
            <Select onChange={(ship) => setShip(ship)} placeholder='Выберите судно'>
              <Option value={1}>МИХАИЛ СВЕТЛОВ</Option>
              <Option value={2}>ДЕМЬЯН БЕДНЫЙ</Option>
            </Select>
          </Form.Item>
          <Form.Item required name='Desc' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item required name='DescEng' label='Описание (Eng)'>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item required name='mDescription' label='Малое описание'>
            <TextArea />
          </Form.Item>
          <Form.Item required name='mDescriptionEng' label='Малое описание (Eng)'>
            <TextArea />
          </Form.Item>

          {/*  */}
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <h3>Программа</h3>
            <button type='button' onClick={handleAddProgram}>
              Добавить программу
            </button>
            {programs.map((program, programIndex) => (
              <div key={programIndex}>
                <h2>Программа {programIndex + 1}</h2>
                <input
                  type='text'
                  placeholder='Заголовок программы'
                  value={program.title}
                  onChange={(e) => {
                    const updatedPrograms = [...programs]
                    updatedPrograms[programIndex].title = e.target.value
                    setPrograms(updatedPrograms)
                  }}
                />
                <button type='button' onClick={() => handleAddProgramDay(programIndex)}>
                  Добавить день
                </button>
                {program.programDays.map((programDay, programDayIndex) => (
                  <div key={programDayIndex}>
                    <h3>День {programDayIndex + 1}</h3>
                    <input
                      type='text'
                      placeholder='Заголовок дня'
                      value={programDay.title}
                      onChange={(e) => {
                        const updatedPrograms = [...programs]
                        updatedPrograms[programIndex].programDays[programDayIndex].title = e.target.value
                        setPrograms(updatedPrograms)
                      }}
                    />
                    <ul>
                      {programDay.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <div>
                            Время:
                            <input
                              type='text'
                              placeholder='Время'
                              value={item.time}
                              onChange={(e) => {
                                const updatedPrograms = [...programs]
                                updatedPrograms[programIndex].programDays[programDayIndex].items[itemIndex].time = e.target.value
                                setPrograms(updatedPrograms)
                              }}
                            />
                          </div>
                          <div>
                            Описание:
                            <input
                              type='text'
                              placeholder='Описание'
                              value={item.description}
                              onChange={(e) => {
                                const updatedPrograms = [...programs]
                                updatedPrograms[programIndex].programDays[programDayIndex].items[itemIndex].description = e.target.value
                                setPrograms(updatedPrograms)
                              }}
                            />
                          </div>
                          <button type='button' onClick={() => handleDeleteItem(programIndex, programDayIndex, itemIndex)}>
                            Удалить
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button type='button' onClick={() => handleAddItem(programIndex, programDayIndex)}>
                      Добавить элемент
                    </button>
                  </div>
                ))}
                <button type='button' onClick={() => handleDeleteProgram(programIndex)}>
                  Удалить программу
                </button>
              </div>
            ))}
          </div>
          {/*  */}
          <div>
            <h3>Достопримечательности</h3>
            <div>
              <input type='text' placeholder='Заголовок' value={advantagesTitle} onChange={(e) => setAdvantagesTitle(e.target.value)} />
              <input
                type='text'
                placeholder='Описание'
                value={advantagesDescription}
                onChange={(e) => setAdvantagesItemsDescription(e.target.value)}
              />
              <input onChange={getAdvantagesUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
              <button type='button' onClick={handleAddAdvantagesItem}>
                Добавить
              </button>
            </div>
            <ul>
              {advantagesItems?.map((item, index) => (
                <li key={index}>
                  <div>{item.title}</div>
                  <div>{item.description}</div>
                  <a href={item.image} target='blank'>
                    {item.image}
                  </a>
                  <button type='button' onClick={() => handleDeleteAdvantagesItem(index)}>
                    Удалить
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <Form.Item required name='programmDesc' label='Описание программы'>
            <TextArea rows={4} />
          </Form.Item>

          <h3>Цены на еду</h3>
          <Form.Item valuePropName='checked' name='foodRequired'>
            <Checkbox checked={foodBlocked} onChange={(e) => setFoodBlocked(e.target.checked)}>
              Еда включена
            </Checkbox>
          </Form.Item>
          <Form.Item required={!foodBlocked} name='food1' label='Шведский стол'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='food2' label='Завтрак'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='food3' label='Обед'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='food4' label='Ужин'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <h3>Цены на еду для детей с 2-11 лет</h3>
          <Form.Item required={!foodBlocked} name='foodKids1' label='Шведский стол'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='foodKids2' label='Завтрак'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='foodKids3' label='Обед'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <Form.Item required={!foodBlocked} name='foodKids4' label='Ужин'>
            <InputNumber disabled={foodBlocked} min={1} />
          </Form.Item>
          <h3>Цены на каюты</h3>
          <RoomVisibleChecbox inputName='cabin4' required label='4-местная каюта' checkboxName='cabin4_check' />
          <RoomVisibleChecbox inputName='cabin3' required label='3-местная каюта' checkboxName='cabin3_check' />
          <RoomVisibleChecbox inputName='cabin2' required label='2-местная каюта' checkboxName='cabin2_check' />
          <RoomVisibleChecbox inputName='cabin1' required label='1-местная каюта' checkboxName='cabin1_check' />
          <RoomVisibleChecbox inputName='halflux' required label='Полулюкс' checkboxName='halflux_check' />
          <RoomVisibleChecbox inputName='lux' required label='Люкс' checkboxName='lux_check' />
          <RoomVisibleChecbox inputName='cabin1extra' required label='1-местная каюта с доп местом' checkboxName='cabin1extra_check' />
          {/*  */}

          <h3>Что находиться в каюте ?</h3>
          <div>
            {Object.entries(inputs).map(([name, value]) => (
              <div key={name}>
                <label>{name}:</label>
                <TextArea type='text' name={name} value={value} onChange={handleInputChange} />
              </div>
            ))}
          </div>
          <h3>Изображения круиза</h3>
          <input onChange={handleImagesChange} accept='.png, .jpg, .jpeg' name='images' type='file' />
          {images && (
            <div style={{ marginBottom: 12, marginTop: 12, display: 'flex' }}>
              {images?.map((item, index) => {
                return (
                  <a key={index} style={{ marginLeft: 8, marginRight: 8 }} target='blank' href={item}>
                    <img style={{ height: 56, width: 56 }} src={item} />
                  </a>
                )
              })}
            </div>
          )}

          <h3>Изображения кают</h3>
          <div>
            {Object.entries(cabinImages).map(([name, value]) => (
              <div key={name}>
                <label>{name}:</label>
                <input onChange={handleCabinImagesChange} accept='.png, .jpg, .jpeg' name={name} type='file' />

                {value[0] && (
                  <div style={{ display: 'flex' }}>
                    {value?.map((item, index) => {
                      return (
                        <div style={{ marginRight: 8 }} key={index}>
                          <a target='blank' href={item} style={{ height: 50, width: 50, marginTop: 10, marginBottom: 10 }}>
                            {value[0] && <img style={{ height: 50, width: 50 }} src={item}></img>}
                          </a>
                        </div>
                      )
                    })}
                  </div>
                )}
                {/* <div style={{height:50, width:50, marginTop:10, marginBottom:10}}>{value[0] && <img style={{height:50, width:50}} src={value[0]}></img>}</div> */}
                {/* <TextArea
                type="text"
                name={name}
                value={value}
                onChange={handleInputChange}
              /> */}
              </div>
            ))}
          </div>

          {/*  */}
          <Form.Item required label='Изображение'>
            <input onChange={getUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
          </Form.Item>
          <Form.Item required label='Изображение тура на главной'>
            <input onChange={getRouteUpload} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
            {routeImage ? (
              <a href={routeImage} target='blank'>
                {routeImage}
              </a>
            ) : null}
          </Form.Item>

          {/*  */}
          {/* <div>
              <div>
                <input
                  type="text"
                  placeholder="Заголовок"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Описание"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <button onClick={handleAddItem}>Добавить</button>
              </div>
              <ul>
                {items.map((item, index) => (
                  <li key={index}>
                    <div>{item.title}</div>
                    <div>{item.description}</div>
                    <button onClick={() => handleDeleteItem(index)}>Удалить</button>
                  </li>
                ))}
              </ul>
            </div> */}
          {/*  */}

          <Button htmlType='submit' loading={loading || loading1} type='primary'>
            Создать
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateOneRoute
