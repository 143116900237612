import { Button, Checkbox } from 'antd'
import styled from 'styled-components'
import TodayDate from './TodayDate'

const PassengerItem = ({ item, onChange, isHistory = false, canDo, onClick }) => {
  return (
    <Item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <div>ФИО: {`${item.surname} ${item.name} ${item.patronymic}`}</div>
        <div>Телефон: {`${item.phone ? item.phone : 'не указан'}`}</div>
        <div>Дата рождения: {TodayDate(new Date(item.dateOfBirth), true)}</div>
        <div>
          {item.documentType}: {item.documentNumber}
        </div>
        <div>Питание: {item.food}</div>
        <Checkbox defaultChecked={item.arrived} disabled onChange={(e) => onChange(e.target.checked, item.id)}>
          Прибыл
        </Checkbox>
      </div>
      {!isHistory && canDo && (
        <Button type='primary' style={{ marginLeft: 5 }} ghost onClick={onClick}>
          Изменить
        </Button>
      )}
    </Item>
  )
}
const Item = styled.div`
  border-top: 1px solid #959595;
  padding: 5px 0;
`
export default PassengerItem
