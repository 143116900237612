import { Button, Form, message, Input, Spin } from 'antd'
import Top from '../../components/Top'
import { useMutation } from '@apollo/client'
import { CREATE_ONE_FAQ } from '../../gqls/faq'
import { useEffect } from 'react'
import { useUser } from '../../utils/hooks'
const { TextArea } = Input

const CreateOneFAQ = ({ history }) => {
  const { user, loading: loadingMe } = useUser()
  useEffect(() => {
    if (!user?.role?.canFAQ) history.goBack()
  })

  const [createOneFAQ, { loading }] = useMutation(CREATE_ONE_FAQ, {
    onCompleted: () => history.push('/faq'),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleCreate = (v) => createOneFAQ({ variables: { data: { ...v } } })
  if (loadingMe) return <Spin />
  if (!user?.role?.canCreateRole) return 'Ошибка'
  return (
    <>
      <Top title='Добавить FAQ' />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Вопрос' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' rules={[{ required: true }]} label='Ответ'>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item name='titleEng' rules={[{ required: true }]} label='Вопрос на английском'>
            <Input />
          </Form.Item>
          <Form.Item name='descriptionEng' rules={[{ required: true }]} label='Ответ на английском'>
            <TextArea rows={5} />
          </Form.Item>
          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateOneFAQ
