import { Checkbox, Form, InputNumber } from 'antd'
import { useState } from 'react'

const RoomVisibleChecbox = ({ blocked = false, required = false, inputName, label, checkboxName }) => {
  const [block, setBlock] = useState(blocked)
  const onChecked = () => setBlock(!block)

  return (
    <div className='align-center'>
      <Form.Item required={required} name={inputName} label={label}>
        <InputNumber min={1} disabled={block} />
      </Form.Item>
      <Form.Item valuePropName='checked' initialValue={blocked} name={checkboxName}>
        <Checkbox onChange={onChecked} style={{ marginLeft: 10 }}>
          Не продавать
        </Checkbox>
      </Form.Item>
    </div>
  )
}

export default RoomVisibleChecbox
