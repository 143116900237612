import { Button } from "antd"
import { genPdfTravelPack } from "../utils/pdfgen"
import PDF from "./pdfTravelPack.js"
import { useQuery } from "@apollo/client"
import { FIND_MANY_SETTINGS } from "../gqls/settings"
// import { useState } from "react"


const PdfTravelPackDownloadButton = (ticketData) => {
    const {data, loading} = useQuery(FIND_MANY_SETTINGS)
    // persentCommissionFee
    return(
        <>
        <PDF ticketData={ticketData.ticketData} ids={ticketData.ids} persentCommissionFee={data?.findManySettings[0]?.persentCommissionFee} />
        <Button
        // className={cx(classes.button, classes.buttonFlex)}
        onClick={() =>{genPdfTravelPack(ticketData.ids)}}
      >
        Скачать путевку
      </Button>
      </>
    )
}

export default PdfTravelPackDownloadButton