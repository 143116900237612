import { gql } from '@apollo/client'

export const FIND_MANY_IMAGES = gql`
  query ($where: ImagesWhereInput) {
    findManyImages(where:$where){
      id
      createdAt
      updatedAt
      visible
      blocked
      title
      image
    }
  }
`

export const CREATE_ONE_IMAGE = gql`
  mutation ($data: ImagesCreateInput!) {
    createOneImages(data: $data) {
      id
    }
  }
`

export const DELETE_ONE_IMAGE = gql`
  mutation ($where: ImagesWhereUniqueInput!) {
    deleteOneImages(where: $where) {
      id
    }
  }
`