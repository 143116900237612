import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'
const { Option } = Select
const FormModal = ({ visible, food, foodRequired, amount, setModVis, passengers, setPass, initial, kids = false }) => {
  const [docType, setDocType] = useState('Паспорт')
  const [form] = Form.useForm()
  const visToggle = () => {
    initial.setInitial(null)
    setModVis(!visible)
  }
  useEffect(() => {
    if (initial.initial) {
      form.setFieldsValue(initial.initial)
    } else {
      form.resetFields()
    }
  }, [initial.initial, form])

  const handleAdd = (v) => {
    const arr = [...passengers]
    let minus = 0
    if (initial.initial) {
      const obj1 = { ...v, id: initial.initial.id, dateOfBirth: v.date.toISOString() }
      if (foodRequired) obj1.food = 'Входит в стоимость'
      arr.forEach((item, index) => {
        if (item.id === initial.initial.id) {
          arr[index] = obj1
          return
        }
      })
      minus = getFoodPrice(initial.initial.food)
    } else {
      arr.push({
        ...v,
        id: `${passengers.length + 1}`,
        dateOfBirth: v.date.toISOString(),
      })
    }
    setPass(arr)
    if (!foodRequired) {
      amount.setAmount(amount.amount + getFoodPrice(v.food) - minus)
    }
    form.resetFields()
    visToggle()
  }

  const getFoodPrice = (string) => {
    let item = 0
    switch (string) {
      case 'Не выбрано':
        break
      case 'Шведский стол':
        item = food[0]
        break
      case 'Завтрак':
        item = food[1]
        break
      case 'Обед':
        item = food[2]
        break
      case 'Ужин':
        item = food[3]
        break
      default:
        item = 0
    }
    return item
  }
  const onDocTypeChange = (e) => {
    form.setFieldsValue({ documentNumber: '' })
    setDocType(e)
  }

  const getLength = () => {
    if (docType === 'Паспорт') return 10
    if (docType === 'Свидетельство о рождении') return 9
    return null
  }
  return (
    <Modal title='Добавление пассажира' open={visible} footer={null} onCancel={visToggle}>
      <Form form={form} onFinish={handleAdd} labelCol={{ span: 8 }}>
        <Form.Item name='surname' rules={[{ required: true }]} label='Фамилия'>
          <Input />
        </Form.Item>
        <Form.Item name='name' rules={[{ required: true }]} label='Имя'>
          <Input />
        </Form.Item>
        <Form.Item name='patronymic' rules={[{ required: true }]} label='Отчество'>
          <Input />
        </Form.Item>
        <Form.Item name='phone' rules={[{ required: true }]} label='Телефон'>
          <Input />
        </Form.Item>
        <Form.Item name='date' rules={[{ required: true }]} label='Дата рождения'>
          <DatePicker />
        </Form.Item>
        <Form.Item name='documentType' rules={[{ required: true }]} label='Документ'>
          <Select onChange={(e) => onDocTypeChange(e)} placeholder='Выберите документ'>
            {!kids && <Option value='Паспорт'>Паспорт</Option>}
            <Option value='Свидетельство о рождении'>Свидетельство о рождении</Option>
            <Option value='Заграничный паспорт'>Заграничный паспорт</Option>
            <Option value='Иностранный документ'>Иностранный документ</Option>
          </Select>
        </Form.Item>
        <Form.Item name='documentNumber' rules={[{ required: true }]} label='Серия, номер'>
          <Input minLength={getLength()} maxLength={getLength()} />
        </Form.Item>
        <Form.Item name='food' rules={[{ required: !foodRequired }]} label='Питание'>
          <Select disabled={foodRequired} placeholder={foodRequired ? 'Входит в стоимость' : 'Выберите питание'}>
            <Option value='Не выбрано'>Не выбрано</Option>
            <Option value='Шведский стол'>Шведский стол</Option>
            <Option value='Завтрак'>Завтрак</Option>
            <Option value='Обед'>Обед</Option>
            <Option value='Ужин'>Ужин</Option>
          </Select>
        </Form.Item>
        <div className='flex' style={{ justifyContent: 'flex-end' }}>
          {initial.initial && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                let arr = [...passengers]
                arr.forEach((item, index) => {
                  if (item === initial.initial) {
                    arr.splice(index, 1)
                    amount.setAmount(amount.amount - getFoodPrice(item.food))
                  }
                })
                setPass(arr)
                visToggle()
                form.resetFields()
              }}
              danger
              ghost
            >
              Удалить
            </Button>
          )}
          <Button htmlType='submit' type='primary'>
            {!initial.initial ? 'Добавить' : 'Изменить'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
export default FormModal
