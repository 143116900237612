import { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Top from '../../components/Top'
import { Button, message, Modal, Spin, Tabs } from 'antd'
import { FIND_MANY_ROUTE, UPDATE_ONE_ROUTE } from '../../gqls/tours'
import MidPlace from '../../components/MidPlace'
import BotPlace from '../../components/BotPlace'
import LegendWrap from '../../components/Legend'
import { useState } from 'react'
import PassengerItem from '../../components/PassengerItem'
import DeleteBookButton from '../../components/DeleteBookButton'
import FormModal from '../../components/FormModal'
import PassengerButton from '../../components/PassengerButton'
import { rooms } from '../../utils/rooms'
import { useUser } from '../../utils/hooks'
import CodeInputModal from '../../components/CodeInputModal'
import { Link } from 'react-router-dom'
import FormUpdateModal from '../../components/FormUpdateModal'
import PdfDownloadButton from '../../components/downloadBookedPDF'
import TextArea from 'antd/lib/input/TextArea'
import PdfTravelPackDownloadButton from '../../components/downloadTravelPackPDF'
import TodayDate from '../../components/TodayDate'
const { TabPane } = Tabs

const Tickets = ({ match, history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modVis, setModVis] = useState(false)
  const [modVisKids, setModVisKids] = useState(false)
  const [passengers, setPassengers] = useState([])
  const [kidsPassengers, setKidsPassengers] = useState([])
  const [number, setNumber] = useState(null)
  const [maxLength, setMaxLength] = useState(null)
  const [isBooked, setIsBooked] = useState(null)
  const [isBookedAdmin, setIsBookedAdmin] = useState(null)
  const [amount, setAmount] = useState(0)
  const [modVisTeens, setModVisTeens] = useState(false)
  const [teenPassengers, setTeensPassengers] = useState([])
  const [initial, setInitial] = useState(null)
  const [modVisUpdate, setModVisUpdate] = useState(false)

  const [commentState, setCommentState] = useState(null)

  const modalToggle = () => setIsModalVisible(!isModalVisible)
  const { id } = match.params
  const { user, loading: loadingMe } = useUser()

  useEffect(() => {
    if (!user?.role?.canViewRoute) history.goBack()
  })
  const { data, loading, refetch } = useQuery(FIND_MANY_ROUTE, {
    fetchPolicy: 'network-only',
    variables: { where: { id: { equals: id } } },
  })

  const [updateOneRoute, { loading: loadingRoute }] = useMutation(UPDATE_ONE_ROUTE, {
    onCompleted: refetch,
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  if (loading || loadingMe) return 'Загрузка...'
  const routeTur = data?.findManyRoute[0]
  if (!routeTur) return 'Ошибка'
  const role = user.role

  const bookRoom = (room, passengers, amount) => {
    if (!loadingRoute) {
      updateOneRoute({
        variables: {
          where: { id },
          data: { bookedRoom: { create: { room, amount, admin: { connect: { id: user.id } }, passengers: { createMany: { data: passengers } } } } },
        },
      })
    }
  }

  // const deleteBookRoom = (roomId) => {
  //   if (!loadingRoute) {
  //     updateOneRoute({
  //       variables: {
  //         where: { id },
  //         data: { bookedRoom: { delete: { id: roomId } } },
  //       },
  //     })
  //   }
  // }

  const returnBookRoom = (roomId) => {
    if (!loadingRoute) {
      updateOneRoute({
        variables: {
          where: { id },
          data: { bookedRoom: { update: { where: { id: roomId }, data: { isReturned: { set: true } } } } },
        },
      })
    }
  }

  const arrivedHandler = (isArrived, passId) => {
    if (!loadingRoute) {
      updateOneRoute({
        variables: {
          where: { id },
          data: {
            ticket: {
              update: {
                where: { id: isBooked?.id },
                data: { passengers: { update: { where: { id: passId }, data: { arrived: { set: isArrived } } } } },
              },
            },
          },
        },
      })
    }
  }

  const arrivedAdminHandler = (isArrived, passId) => {
    if (!loadingRoute) {
      updateOneRoute({
        variables: {
          where: { id },
          data: {
            bookedRoom: {
              update: {
                where: { id: isBookedAdmin?.id },
                data: { passengers: { update: { where: { id: passId }, data: { arrived: { set: isArrived } } } } },
              },
            },
          },
        },
      })
    }
  }

  const commentHandler = () => {
    if (!loadingRoute) {
      updateOneRoute({
        variables: {
          where: { id },
          data: {
            bookedRoom: {
              update: {
                where: { id: isBookedAdmin?.id },
                data: { comment: { set: commentState } },
              },
            },
          },
        },
      })
    }
  }

  const onTapHandler = (number, isBooked, isBookedAdmin, color) => {
    if (!loadingRoute) {
      setNumber(number)
      setMaxLength(rooms.find((item) => item.color === color).max)
      setAmount(routeTur.Pricing[rooms.find((item) => item.color === color).indx])
      setIsBooked(isBooked)
      setIsBookedAdmin(isBookedAdmin)
      setCommentState(isBookedAdmin?.comment ? isBookedAdmin?.comment : null)
      modalToggle()
    }
  }

  const bookHandler = () => {
    let pass = [...passengers, ...teenPassengers, ...kidsPassengers]
    pass.forEach((item) => {
      delete item.date
      delete item.id
    })
    bookRoom(number.toString(), pass, amount)
    setPassengers([])
    setKidsPassengers([])
    setAmount(0)
    modalToggle()
  }
  return (
    <>
      <Top
        title='Каюты'
        action={loadingRoute ? <Spin size='large' spinning={loadingRoute} /> : role.canViewReport && <Link to={`/routes/charts/${id}`}>Отчет</Link>}
      />
      <Tabs type='card'>
        <TabPane tab='Нижняя палуба' key='1'>
          <LegendWrap routeTur={routeTur}>
            <BotPlace onTap={onTapHandler} routeTur={routeTur} />
          </LegendWrap>
        </TabPane>
        <TabPane tab='Средняя палуба' key='2'>
          <LegendWrap routeTur={routeTur}>
            <MidPlace onTap={onTapHandler} routeTur={routeTur} />
          </LegendWrap>
        </TabPane>
      </Tabs>
      {isBooked ? (
        <Modal
          title={'Каюта ' + number}
          open={isModalVisible}
          onCancel={modalToggle}
          footer={[
            role.canDeleteBooking && <CodeInputModal key='refund' date={routeTur.date} ticket={isBooked} />,
            <Button key='close' type='primary' onClick={modalToggle}>
              Закрыть
            </Button>,
          ]}
        >
          <FormUpdateModal
            visible={modVisUpdate}
            foodRequired={routeTur.foodRequired}
            setModVis={setModVisUpdate}
            initial={{ initial, setInitial }}
            refetch={refetch}
            modalToggle={modalToggle}
          />
          <p style={{ marginBottom: 5 }}>Дата бронирования: {TodayDate(new Date(isBooked?.createdAt), true)}</p>
          <h3>Пассажиры:</h3>
          {isBooked?.passengers.map((item) => (
            <PassengerItem
              onChange={arrivedHandler}
              canDo={role.canUpdatePassengersInfo}
              key={item.id}
              item={item}
              onClick={() => {
                setInitial(item)
                setModVisUpdate(true)
              }}
            />
          ))}
        </Modal>
      ) : (
        <Modal
          title={'Каюта ' + number}
          open={isModalVisible}
          onCancel={modalToggle}
          footer={
            isBookedAdmin
              ? [
                  isBookedAdmin.passengers[0] && (
                    <PdfDownloadButton
                      ticketData={isBookedAdmin}
                      ids={[isBookedAdmin.id + 1, isBookedAdmin.id + 2, isBookedAdmin.id + 3, isBookedAdmin.id + 4]}
                    />
                  ),
                  isBookedAdmin.passengers[0] && <PdfTravelPackDownloadButton ticketData={isBookedAdmin} ids={[isBookedAdmin.id + 100]} />,
                  <DeleteBookButton
                    disabled={!role.canDeleteBooking}
                    key='delete'
                    title={`Отметить возврат средств за каюту ${number}?`}
                    onConfirm={() => returnBookRoom(isBookedAdmin.id)}
                    closeModal={modalToggle}
                    text='Вернуть'
                  />,
                ]
              : [
                  <Button key='save' type='primary' onClick={bookHandler}>
                    Забронировать
                  </Button>,
                ]
          }
        >
          {!isBookedAdmin ? (
            <>
              <FormModal
                visible={modVis}
                food={routeTur.food}
                foodRequired={routeTur.foodRequired}
                amount={{ amount, setAmount }}
                setModVis={setModVis}
                passengers={passengers}
                setPass={setPassengers}
                initial={{ initial, setInitial }}
              />
              <h3>
                Пассажиры:
                {[...passengers, ...teenPassengers].length !== maxLength && (
                  <Button disabled={!role.canAddBooking} type='primary' style={{ marginLeft: 5 }} ghost onClick={() => setModVis(true)}>
                    {passengers[0] ? '+' : 'Добавить'}
                  </Button>
                )}
              </h3>
              <div className='flex' style={{ flexWrap: 'wrap', marginBottom: 8 }}>
                {passengers.map((item) => (
                  <PassengerButton
                    key={item.documentNumber}
                    name={item.name}
                    onClick={() => {
                      setInitial(item)
                      setModVis(true)
                    }}
                  />
                ))}
              </div>
              <FormModal
                visible={modVisTeens}
                food={routeTur.foodKids}
                foodRequired={routeTur.foodRequired}
                amount={{ amount, setAmount }}
                setModVis={setModVisTeens}
                passengers={teenPassengers}
                setPass={setTeensPassengers}
                initial={{ initial, setInitial }}
                kids={true}
              />
              <h3>
                Дети от 2 до 12 лет:
                {[...passengers, ...teenPassengers].length !== maxLength && (
                  <Button disabled={!role.canAddBooking} type='primary' style={{ marginLeft: 5 }} ghost onClick={() => setModVisTeens(true)}>
                    {teenPassengers[0] ? '+' : 'Добавить'}
                  </Button>
                )}
              </h3>
              <div className='flex' style={{ flexWrap: 'wrap', marginBottom: 8 }}>
                {teenPassengers.map((item) => (
                  <PassengerButton
                    key={item.documentNumber}
                    name={item.name}
                    onClick={() => {
                      setInitial(item)
                      setModVisTeens(true)
                    }}
                  />
                ))}
              </div>
              <FormModal
                visible={modVisKids}
                food={[0, 0, 0, 0]}
                foodRequired={routeTur.foodRequired}
                amount={{ amount, setAmount }}
                setModVis={setModVisKids}
                passengers={kidsPassengers}
                setPass={setKidsPassengers}
                kids={true}
                initial={{ initial, setInitial }}
              />
              <h3>
                Дети без предоставления места:
                {kidsPassengers.length < 1 && (
                  <Button disabled={!role.canAddBooking} type='primary' style={{ marginLeft: 5 }} ghost onClick={() => setModVisKids(true)}>
                    {kidsPassengers[0] ? '+' : 'Добавить'}
                  </Button>
                )}
              </h3>
              <div className='flex' style={{ flexWrap: 'wrap', marginBottom: 8 }}>
                {kidsPassengers.map((item) => (
                  <PassengerButton
                    key={item.documentNumber}
                    name={item.name}
                    onClick={() => {
                      setInitial(item)
                      setModVisKids(true)
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <FormUpdateModal
                visible={modVisUpdate}
                foodRequired={routeTur.foodRequired}
                setModVis={setModVisUpdate}
                initial={{ initial, setInitial }}
                refetch={refetch}
                modalToggle={modalToggle}
              />
              <p style={{ marginBottom: 5 }}>Дата бронирования: {TodayDate(new Date(isBookedAdmin?.createdAt), true)}</p>
              <h3>Пассажиры:</h3>
              {isBookedAdmin?.passengers?.map((item, index) => (
                <PassengerItem
                  key={index}
                  canDo={role.canChangeAllPassengersInfo || role.canChangeMyPassengersInfo}
                  onChange={arrivedAdminHandler}
                  item={item}
                  onClick={() => {
                    setInitial(item)
                    setModVisUpdate(true)
                  }}
                />
              ))}
              <h4>Комментарий</h4>
              <TextArea
                defaultValue={commentState}
                value={commentState}
                onChange={(e) => setCommentState(e.target.value)}
                style={{ marginBottom: 16 }}
              ></TextArea>
              <Button onClick={() => commentHandler()} type='primary'>
                Сохранить комментарий
              </Button>
              <h3 style={{ marginTop: 20 }}>
                Забронировал: {isBookedAdmin.admin?.name} {isBookedAdmin.admin?.surname}
              </h3>
            </>
          )}
        </Modal>
      )}
    </>
  )
}
export default Tickets
