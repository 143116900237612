import { gql } from '@apollo/client'

export const FIND_MANY_TICKETS = gql`
  query ($where: TicketWhereInput, $skip: Int, $take: Int) {
    findManyTicket(where: $where, skip: $skip, take: $take) {
      id
      amount
      room
      purchasedIn
      isReturned
      route {
        ship
        route
        date
        duration
        food
        foodKids
      }
      passengers {
        id
        createdAt
        name
        surname
        patronymic
        phone
        dateOfBirth
        documentType
        documentNumber
        food
        arrived
        ticketId
      }
    }
  }
`
export const REFUND_INPUT = gql`
  mutation ($data: RefundInput!) {
    refundInput(data: $data)
  }
`
export const DELETE_ONE_TICKET = gql`
  mutation ($where: TicketWhereUniqueInput!) {
    deleteOneTicket(where: $where) {
      id
    }
  }
`
export const UPDATE_ONE_TICKET = gql`
  mutation ($where: TicketWhereUniqueInput!, $data: TicketUpdateInput!) {
    updateOneTicket(where: $where, data: $data) {
      id
    }
  }
`
