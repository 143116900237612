import { useQuery } from '@apollo/client'
import Top from '../components/Top'
import { DatePicker, Col, Row, Statistic, Button } from 'antd'
import { useEffect, useState } from 'react'
import { FIND_MANY_TICKETS } from '../gqls/tickets'
import { FIND_MANY_BOOKED_ROOM } from '../gqls/tours'
import { Link } from 'react-router-dom'
import { useUser } from '../utils/hooks'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import styled from 'styled-components'
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker
const Start = '2024-05-01T04:07:23.708Z'
const Today = new Date()
const Todaystart = new Date()
Todaystart.setUTCHours(0, 0, 0, 0)

const Main = ({ history }) => {
  const { user } = useUser()
  useEffect(() => {
    if (!user?.role?.canViewReport) history.goBack()
  })
  const [dates, setDates] = useState([Start, Today])
  const [total, setTotal] = useState(0)

  const { data: dataBooked, loading: loadingBooked } = useQuery(FIND_MANY_BOOKED_ROOM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        createdAt: dates[0] && { gte: new Date(dates[0]).toISOString(), lte: new Date(dates[1]).toISOString() },
        isReturned: { equals: false },
      },
    },
  })

  const { data, loading } = useQuery(FIND_MANY_TICKETS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        isReturned: { equals: false },
        createdAt: dates[0] && { gte: new Date(dates[0]).toISOString(), lte: new Date(dates[1]).toISOString() },
      },
    },
    onCompleted: (data) => {
      let total = 0
      let appCount = 0
      let webserviceCount = 0
      data?.findManyTicket.forEach((element) => {
        total += element.amount
        if (element.purchasedIn === 'APP') appCount += 1
        if (element.purchasedIn === 'WEBSERVICE') webserviceCount += 1
      })
      const length = data?.findManyTicket.length ? data?.findManyTicket.length : 1
      setTotal({
        total: total / 100,
        appCount,
        webserviceCount,
        appCountP: (appCount / length) * 100,
        webserviceCountP: (webserviceCount / length) * 100,
      })
    },
  })

  const isDisabled = dates[0] === Start && dates[1] === Today
  const isDisabled2 = dates[0] === Todaystart && dates[1] === Today

  return (
    <>
      <Top
        title='Главная'
        action={
          user?.role?.canViewReport && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NavButton>
                <Link to={`/routes/AllReport/sales/`}>Продажи</Link>
              </NavButton>
              <Link to={`/routes/AllReport/booked/sales/`}>Продажи (бронь)</Link>
            </div>
          )
        }
      />
      <div className='flex' style={{ gap: 20 }}>
        <RangePicker allowClear={false} value={[dayjs(dates[0]), dayjs(dates[1])]} onChange={(dates, dateStrings) => setDates(dateStrings)} />
        <Button onClick={() => setDates([Start, Today])} disabled={isDisabled} type='primary' ghost>
          За все время
        </Button>
        <Button onClick={() => setDates([Todaystart, Today])} disabled={isDisabled2} type='primary' ghost>
          За сегодня
        </Button>
      </div>
      <div style={{ marginTop: 20 }}>
        {loading || loadingBooked ? (
          'Загрузка...'
        ) : (
          <div>
            <Row>
              <Col span={12}>
                <Statistic title='Выручка' value={total?.total} suffix=' ₽' precision={2} />
              </Col>
              <Col span={12}>
                <Statistic title='Путёвок продано' value={data?.findManyTicket.length} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <Statistic title='Куплено в приложении' value={total?.appCount} suffix={`/ ${Math.round(total.appCountP)}%`} />
              </Col>
              <Col span={12}>
                <Statistic title='Куплено на сайте' value={total?.webserviceCount} suffix={`/ ${Math.round(total.webserviceCountP)}%`} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <Statistic title='Забронированно админом' value={dataBooked?.findManyBookedRoom?.length} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

const NavButton = styled.div`
  padding: 5px 10px 5px 0;
  margin-right: 10px;
  border-right: 1px solid #d9d9d9;
`
export default Main
