import { useQuery } from '@apollo/client'
import { FIND_ME_ADMIN } from '../gqls/admin'

export const useUser = () => {
  const { data, loading, error } = useQuery(FIND_ME_ADMIN)
  const user = data ? data.meAdmin : null

  return { user, loading, error }
}

export const getRoomType = (ship, room) => {
  if (ship === 1) {
    if (room >= 100 && room <= 105) {
      return 1
    }
    if (room >= 106 && room <= 113) {
      return 2
    }
    if (room >= 114 && room <= 137) {
      return 4
    }
    if (room >= 200 && room <= 201) {
      return 2
    }
    if (room >= 202 && room <= 208) {
      return 2
    }
    if (room === 209) {
      return 3
    }
    if (room === 210) {
      return 2
    }
    if (room >= 211 && room <= 234) {
      return 2
    }
  } else {
    if (room >= 100 && room <= 105) {
      return 1
    }
    if (room >= 106 && room <= 113) {
      return 2
    }
    if (room >= 114 && room <= 137) {
      return 4
    }
    if (room >= 200 && room <= 201) {
      return 2
    }
    if (room >= 202 && room <= 210) {
      return 2
    }
    if (room >= 211 && room <= 234) {
      return 3
    }
  }
  return null
}

export const mergeAndSumDuplicateObjects = (array) => {
  const objectMap = {}; // Для отслеживания уникальных объектов и их суммирования

  // Обходим массив
  for (const item of array) {
    const key = JSON.stringify(item); // Преобразуем объект в строку для использования в качестве ключа

    // Если объект уже встречался, суммируем его значения
    if (objectMap[key]) {
      objectMap[key].amount += item.amount;
      objectMap[key].count += item.count;
    } else {
      // Если объект встречается впервые, сохраняем его
      objectMap[key] = { ...item };
    }
  }

  // Преобразуем объект objectMap обратно в массив
  const resultArray = Object.values(objectMap);

  return resultArray;
}