export const roomsData = {
    "100": {
      capacity: 1,
      priceIndex: 3
    },
    "101": {
      capacity: 1,
      priceIndex: 3
    },
    "102": {
      capacity: 1,
      priceIndex: 3
    },
    "103": {
      capacity: 1,
      priceIndex: 3
    },
    "104": {
      capacity: 1,
      priceIndex: 3
    },
    "105": {
      capacity: 1,
      priceIndex: 3
    },
    "106": {
      capacity: 2,
      priceIndex: 4
    },
    "107": {
      capacity: 2,
      priceIndex: 4
    },
    "108": {
      capacity: 2,
      priceIndex: 4
    },
    "109": {
      capacity: 2,
      priceIndex: 4
    },
    "110": {
      capacity: 2,
      priceIndex: 4
    },
    "111": {
      capacity: 2,
      priceIndex: 4
    },
    "112": {
      capacity: 2,
      priceIndex: 4
    },
    "113": {
      capacity: 2,
      priceIndex: 4
    },
    "114": {
      capacity: 4,
      priceIndex: 0
    },
    "115": {
      capacity: 4,
      priceIndex: 0
    },
    "116": {
      capacity: 4,
      priceIndex: 0
    },
    "117": {
      capacity: 4,
      priceIndex: 0
    },
    "118": {
      capacity: 4,
      priceIndex: 0
    },
    "119": {
      capacity: 4,
      priceIndex: 0
    },
    "120": {
      capacity: 4,
      priceIndex: 0
    },
    "121": {
      capacity: 4,
      priceIndex: 0
    },
    "122": {
      capacity: 4,
      priceIndex: 0
    },
    "123": {
      capacity: 4,
      priceIndex: 0
    },
    "124": {
      capacity: 4,
      priceIndex: 0
    },
    "125": {
      capacity: 4,
      priceIndex: 0
    },
    "126": {
      capacity: 4,
      priceIndex: 0
    },
    "127": {
      capacity: 4,
      priceIndex: 0
    },
    "128": {
      capacity: 4,
      priceIndex: 0
    },
    "129": {
      capacity: 4,
      priceIndex: 0
    },
    "130": {
      capacity: 4,
      priceIndex: 0
    },
    "131": {
      capacity: 4,
      priceIndex: 0
    },
    "132": {
      capacity: 4,
      priceIndex: 0
    },
    "133": {
      capacity: 4,
      priceIndex: 0
    },
    "135": {
      capacity: 4,
      priceIndex: 0
    },
    "137": {
      capacity: 4,
      priceIndex: 0
    },
    "200": {
      capacity: 2,
      priceIndex: 5
    },
    "201": {
      capacity: 2,
      priceIndex: 5
    },
    "202": {
      capacity: 2,
      priceIndex: 2
    },
    "203": {
      capacity: 2,
      priceIndex: 2
    },
    "204": {
      capacity: 2,
      priceIndex: 2
    },
    "205": {
      capacity: 2,
      priceIndex: 2
    },
    "206": {
      capacity: 2,
      priceIndex: 2
    },
    "207": {
      capacity: 2,
      priceIndex: 2
    },
    "208": {
      capacity: 2,
      priceIndex: 2
    },
    "209": {
      capacity: 3,
      priceIndex: 1
    },
    "210": {
      capacity: 2,
      priceIndex: 2
    },
    "211": {
      capacity: 3,
      priceIndex: 1
    },
    "212": {
      capacity: 3,
      priceIndex: 1
    },
    "213": {
      capacity: 3,
      priceIndex: 1
    },
    "214": {
      capacity: 3,
      priceIndex: 1
    },
    "215": {
      capacity: 3,
      priceIndex: 1
    },
    "216": {
      capacity: 3,
      priceIndex: 1
    },
    "217": {
      capacity: 3,
      priceIndex: 1
    },
    "218": {
      capacity: 3,
      priceIndex: 1
    },
    "219": {
      capacity: 3,
      priceIndex: 1
    },
    "220": {
      capacity: 3,
      priceIndex: 1
    },
    "221": {
      capacity: 3,
      priceIndex: 1
    },
    "222": {
      capacity: 3,
      priceIndex: 1
    },
    "223": {
      capacity: 3,
      priceIndex: 1
    },
    "224": {
      capacity: 3,
      priceIndex: 1
    },
    "225": {
      capacity: 3,
      priceIndex: 1
    },
    "226": {
      capacity: 3,
      priceIndex: 1
    },
    "227": {
      capacity: 3,
      priceIndex: 1
    },
    "228": {
      capacity: 3,
      priceIndex: 1
    },
    "229": {
      capacity: 3,
      priceIndex: 1
    },
    "230": {
      capacity: 3,
      priceIndex: 1
    },
    "231": {
      capacity: 3,
      priceIndex: 1
    },
    "232": {
      capacity: 3,
      priceIndex: 1
    },
    "233": {
      capacity: 3,
      priceIndex: 1
    },
    "234": {
      capacity: 3,
      priceIndex: 1
    }
  };
  
  export const roomsWithExtraPlace = [100, 101, 102, 103, 104, 105];
  
  export const ROOMS_COUNT = {
    Люкс: ["200", "201"],
    Полулюкс: ["106", "107", "108", "109", "110", "111", "112", "113"],
    "1-местная каюта": ["100", "101", "102", "103", "104", "105"],
    "2-местная каюта": ["202", "203", "204", "205", "206", "207", "208", "210"],
    "3-местная каюта": [
      "209",
      "211",
      "212",
      "213",
      "214",
      "215",
      "216",
      "217",
      "218",
      "219",
      "220",
      "221",
      "222",
      "223",
      "224",
      "225",
      "226",
      "227",
      "228",
      "229",
      "230",
      "231",
      "232",
      "233",
      "234"
    ],
    "4-местная каюта": [
      "114",
      "115",
      "116",
      "117",
      "118",
      "119",
      "120",
      "121",
      "122",
      "123",
      "124",
      "125",
      "126",
      "127",
      "128",
      "129",
      "130",
      "131",
      "132",
      "133",
      "135",
      "137"
    ]
  };
  