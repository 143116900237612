import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { rooms } from '../utils/rooms'
import TodayDate from './TodayDate'

const SalesDoc = ({ data, guys, dates, isBooked }) => {
  let divider = 100
  if (isBooked) divider = 1
  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
  })
  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: 'Roboto',
    },
    general: {
      marginBottom: 20,
    },
    bold: {
      fontWeight: 'black',
      fontSize: 12,
      marginRight: 20,
      marginLeft: 10,
    },
    text: {
      marginVertical: 5,
      fontSize: 12,
      textAlign: 'justify',
    },
    h1: {
      marginVertical: 5,
      marginBottom: 15,
      fontSize: 16,
      textAlign: 'justify',
    },
    flex: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    row: {
      flexDirection: 'row',
    },
    lil: {
      fontSize: 10,
      paddingHorizontal: 5,
      borderWidth: 1,
      width: '100%',
      borderColor: 'gray',
    },
    flexStart: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  })

  const getRoomType = (room, ship) => {
    if (ship === 1) {
      if (room >= 100 && room <= 105) {
        return '#EEC1EE'
      }
      if (room >= 106 && room <= 113) {
        return '#7FFF6A'
      }
      if (room >= 114 && room <= 137) {
        return '#FFA500'
      }
      if (room >= 200 && room <= 201) {
        return '#FF5757'
      }
      if (room >= 202 && room <= 208) {
        return '#F1C84C'
      }
      if (room === 209) {
        return '#8BF8FF'
      }
      if (room === 210) {
        return '#F1C84C'
      }
      if (room >= 211 && room <= 234) {
        return '#8BF8FF'
      }
    } else {
      if (room >= 100 && room <= 105) {
        return '#EEC1EE'
      }
      if (room >= 106 && room <= 113) {
        return '#7FFF6A'
      }
      if (room >= 114 && room <= 137) {
        return '#FFA500'
      }
      if (room >= 200 && room <= 201) {
        return '#FF5757'
      }
      if (room >= 202 && room <= 210) {
        return '#7FFF6A'
      }
      if (room >= 211 && room <= 234) {
        return '#8BF8FF'
      }
    }
    return null
  }

  const GetSum = ({ type }) => {
    let sum = 0,
      reSum = 0,
      total = 0,
      reTotal = 0
    data.forEach((item) => {
      if (type.color === getRoomType(Number(item.room), item.route.ship) && item.isReturned) {
        reSum++
        reTotal += item.amount
      }
    })
    data.forEach((item) => {
      if (type.color === getRoomType(Number(item.room), item.route.ship)) {
        sum++
        total += item.amount
      }
    })
    return (
      <View style={styles.row}>
        <Text style={{ ...styles.lil, width: '40%' }}>{type.h}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{reSum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{reTotal / divider}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{sum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{total / divider}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{sum - reSum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{(total - reTotal) / divider}</Text>
      </View>
    )
  }
  const food = [
    { ru: 'Шведский стол', en: 'all' },
    { ru: 'Завтрак', en: 'breakfast' },
    { ru: 'Обед', en: 'lunch' },
    { ru: 'Ужин', en: 'dinner' },
    { ru: 'Не выбрано', en: 'no' },
  ]
  const GetFood = ({ food, index }) => {
    let count = guys.filter((item) => item.food.includes(food.ru) || item.food.includes(food.en))
    let reCount = guys.filter((item) => (item.food.includes(food.ru) || item.food.includes(food.en)) && item.isReturned)
    let reTotal = 0
    let total = 0
    const getFoodPrice = (age, route) => {
      if (index === 4) {
        return 0
      } else if (age >= 12) {
        return route.food[index]
      } else if (age >= 12 && age <= 2) {
        return route.foodKids[index]
      } else {
        return 0
      }
    }
    count.forEach((item) => {
      const age = new Date(Date.now() - new Date(item.dateOfBirth)).getUTCFullYear()
      let each = getFoodPrice(age, item.route)
      total += each ? each : 0
    })
    reCount.forEach((item) => {
      const age = new Date(Date.now() - new Date(item.dateOfBirth)).getUTCFullYear()
      let each = getFoodPrice(age, item.route)
      reTotal += each ? each : 0
    })
    return (
      <View style={styles.row}>
        <Text style={{ ...styles.lil, width: '40%' }}>{food.ru}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{reCount.length}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{reTotal}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{count.length}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{total}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{count.length - reCount.length}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{total - reTotal}</Text>
      </View>
    )
  }

  const GetTotalCountsSum = () => {
    let sum = 0,
      reSum = 0,
      total = 0,
      reTotal = 0

    rooms.forEach((room) => {
      data.forEach((item) => {
        if (room.color === getRoomType(Number(item.room), item.route.ship) && item.isReturned) {
          reSum++
          reTotal += item.amount
        }
      })
      data.forEach((item) => {
        if (room.color === getRoomType(Number(item.room), item.route.ship)) {
          sum++
          total += item.amount
        }
      })
    })
    return (
      <View style={styles.row}>
        <Text style={{ ...styles.lil, width: '40%' }}>Итого</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{reSum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{reTotal / divider}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{sum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{total / divider}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{sum - reSum}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{(total - reTotal) / divider}</Text>
      </View>
    )
  }

  const GetTotalCountsFood = () => {
    let endcount = 0,
      endreCount = 0,
      reCost = 0,
      cost = 0

    food.forEach((food, index) => {
      let count = guys.filter((item) => item.food.includes(food.ru) || item.food.includes(food.en))
      let reCount = guys.filter((item) => (item.food.includes(food.ru) || item.food.includes(food.en)) && item.isReturned)
      endcount += count.length
      endreCount += reCount.length
      const getFoodPrice = (age, route) => {
        if (index === 4) {
          return 0
        } else if (age >= 12) {
          return route.food[index]
        } else if (age >= 12 && age <= 2) {
          return route.foodKids[index]
        } else {
          return 0
        }
      }

      count.forEach((item) => {
        const age = new Date(Date.now() - new Date(item.dateOfBirth)).getUTCFullYear()
        let each = getFoodPrice(age, item.route)
        cost += each ? each : 0
      })
      reCount.forEach((item) => {
        const age = new Date(Date.now() - new Date(item.dateOfBirth)).getUTCFullYear()
        let each = getFoodPrice(age, item.route)
        reCost += each ? each : 0
      })
    })

    return (
      <View style={styles.row}>
        <Text style={{ ...styles.lil, width: '40%' }}>Итого</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{endreCount}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{reCost}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{endcount}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{cost}</Text>
        <Text style={{ ...styles.lil, width: '8%' }}>{endcount - endreCount}</Text>
        <Text style={{ ...styles.lil, width: '12%' }}>{cost - reCost}</Text>
      </View>
    )
  }

  return (
    <Document>
      <Page orientation='landscape' style={styles.body}>
        <View>
          <Text style={styles.h1}>Продажи</Text>
          <View style={{ ...styles.general, ...styles.flexStart }}>
            <View style={styles.flex}>
              <Text style={styles.text}>Параметры:</Text>
              <Text style={styles.bold}></Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.text}>Период:</Text>
              <Text style={styles.bold}>{TodayDate(new Date(dates[0]), true) + '— ' + TodayDate(new Date(dates[1]), true)}</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={{ ...styles.lil, width: '40%' }}>Вид номенклатуры</Text>
              <Text style={{ ...styles.lil, width: '20%' }}>Возврат</Text>
              <Text style={{ ...styles.lil, width: '20%' }}>Продажа</Text>
              <Text style={{ ...styles.lil, width: '20%' }}>Итого</Text>
            </View>
            <View style={styles.row}>
              <Text style={{ ...styles.lil, width: '40%' }}>Номенклатура</Text>
              <Text style={{ ...styles.lil, width: '8%' }}>Кол-во</Text>
              <Text style={{ ...styles.lil, width: '12%' }}>Сумма ₽</Text>
              <Text style={{ ...styles.lil, width: '8%' }}>Кол-во</Text>
              <Text style={{ ...styles.lil, width: '12%' }}>Сумма ₽</Text>
              <Text style={{ ...styles.lil, width: '8%' }}>Кол-во</Text>
              <Text style={{ ...styles.lil, width: '12%' }}>Сумма ₽</Text>
            </View>
            <View style={{ marginTop: 10 }} />
            <View style={styles.row}>
              <Text style={{ ...styles.lil, width: '40%' }}>Тариф</Text>
              <Text style={{ ...styles.lil, width: '60%' }} />
            </View>
            {rooms.map((item) => item.indx >= 0 && <GetSum type={item} key={item.color} />)}
            <View style={{ marginTop: 10 }} />
            <GetTotalCountsSum />
            <View style={{ marginTop: 20 }} />
            <View style={styles.row}>
              <Text style={{ ...styles.lil, width: '40%' }}>Питание</Text>
              <Text style={{ ...styles.lil, width: '60%' }} />
            </View>
            {food.map((item, index) => (
              <GetFood key={item.ru} food={item} index={index} />
            ))}
            <View style={{ marginTop: 10 }} />
            <GetTotalCountsFood />
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      </Page>
    </Document>
  )
}

export default SalesDoc
